<template>
  <div
    v-if="canEdit"
    ref="fieldViewerInput"
    :class="{ 'static-field p-4': cloneField.fieldAttributeType == 'static_text' }"
  >
    <label
      :for="cloneField.label.toLowerCase()"
      class="align-middle"
      :data-tc-check-label="cloneField.label"
    >
      <template v-if="field.fieldAttributeType == 'static_text'">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="sanitizeHTML(convertedText)"/>
        <!-- eslint-enable vue/no-v-html -->
      </template>
      <template v-else>
        {{ cloneField.label }}
        <small v-if="cloneField.name == 'subject'">
          (Maximum 200 characters)
        </small>
        <span
          v-show="cloneField.required"
          class="required"
        />
      </template>
      
    </label>
    <component
      :is="`${toHyphenCase(cloneField.fieldAttributeType)}Input`"
      :id="cloneField.label.toLowerCase()"
      ref="field"
      :data-tc-text-field="cloneField.name"
      :field="cloneField"
      :value="newValue"
      :attachment-ids.sync="attachmentIds"
      :mfa-phone-field="mfaPhoneField"
      :module-name="companyModule"
      :disabled="field.disabled"
      :required="field.required"
      @attachment-uploading-status="uploadingStatus"
      @input="emitValue"
      @update="updateFormField"
      @open-modal="openMfaPhoneModal"
      @delete="deleteValue"
      @check-unsaved-attachments="checkUnusedAttachments"
    />
    <!-- eslint-disable vue/no-v-html -->
    <span
      v-if="cloneField.note"
      class="text-info small formatted-text"
      v-html="sanitizeHTML(cloneField.note)"
    />
    <!-- eslint-enable vue/no-v-html -->
    <slot name="error" />
  </div>
</template>

<script>
  import strings from 'mixins/string';
  import _cloneDeep from 'lodash/cloneDeep';
  import _get from 'lodash/get';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  import fieldPermissions from 'mixins/custom_forms/field_permissions';
  import loggedIn from 'mixins/logged_in';
  import _camelCase from "lodash/camelCase";
  import customFormHelper from 'mixins/custom_form_helper';
  import permissionsHelper from 'mixins/permissions_helper';
  import { checkUnusedAttachments } from 'mixins/trix_vue_helper';
  import staticTextHelper from 'mixins/static_text_helper';
  import * as formViewerFields from './form_viewer_fields/index';

  export default {
    components: {
      ClipLoader,
      ...formViewerFields,
    },
    mixins: [strings, fieldPermissions, customFormHelper, permissionsHelper, loggedIn, checkUnusedAttachments, staticTextHelper],
    props: {
      field: {
        required: true,
      },
      value: {
        default: null,
      },
      attachmentIds: {
        type: Array,
        default: () => [],
      },
      setDefaultKey: {
        type: Boolean,
        default: true,
      },
      mfaPhoneField: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        userFields: ['first_name', 'last_name', 'email'],
        companyModule: '',
        cloneField: _cloneDeep(this.field),
      };
    },
    computed: {
      isHelpCenterPortal() {
        return window.location.href.startsWith("/help_center");
      },
      defaultValue() {
        return this.cloneField.defaultValue;
      },
      newValue() {
        switch(this.cloneField.fieldAttributeType) {
          case "asset_list":
          case "contract_list":
          case "people_list":
          case "location_list":
          case "telecom_list":
          case "vendor_list": {
            let ids = [];
            if (this.setDefaultKey) {
              this.$emit(`update-set-default-key`, this.cloneField.id);
              if (this.populateUserLocation && (this.companyModule === "helpdesk" || this.isHelpCenterPortal)) {
                this.$currentContributorLocationIds.forEach((locationId) => {
                  ids.push(locationId);
                });
              }
              if (ids.length === 0 && this.cloneField.defaultValue) {
                ids = JSON.parse(this.cloneField.defaultValue);
              }
            } else {
              this.value.forEach((val) => {
                if (val.valueStr !== "current") {
                  ids = this.value.map((s) => s.valueInt || s.valueStr);
                }
              });
            }
            if (this.cloneField.fieldAttributeType === 'people_list') {
              const idx = ids.indexOf("current");
              if (idx > -1 && !this.$superAdminUser) {
                ids[idx] = this.$currentContributorId;
              }
            }
            return ids;
          }
          case "tag": {
            let valueStr= [];
            valueStr = this.value.map((s) => s.valueStr);
            return valueStr;
          }
          case "category":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "number":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "date":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "status":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "phone":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "email":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "priority":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "text":
            if (this.isUserValue()) {
              return this.cloneField.targetUser[_camelCase(this.cloneField.name)];
            }
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "rich_text":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "text_area":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "attachment":
            return this.value.map(s => s.attachmentId);
          case "list":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "radio_button":
            return _get(this, "value[0].valueStr", this.defaultValue);
          case "checkbox": {
            let checkboxVal = [];
            if (this.setDefaultKey) {
              this.$emit(`update-set-default-key`, this.cloneField.id);
              if (checkboxVal.length === 0 && this.cloneField.defaultValue) {
                checkboxVal = JSON.parse(this.cloneField.defaultValue);
              }
            } else {
              checkboxVal = this.value.map((s) => s.valueStr);
            }
            return checkboxVal;
          }
          default:
            return _get(this, "value[0].valueInt", this.defaultValue);
        }
      },
      populateUserLocation() {
        return !this.cloneField.defaultValue &&
               this.cloneField.fieldAttributeType === "location_list" &&
               this.$currentContributorLocationIds &&
               this.$currentContributorLocationIds.length;
      },
    },
    methods: {
      onWorkspaceChange() {
        this.companyModule = this.getCompanyModule;
      },
      uploadingStatus(status) {
        this.$emit('attachment-uploading-status', status);
      },
      emitValue(value) {
        this.$emit("input", this.cloneField, value);
        if (this.isUserValue()) {
          this.cloneField.targetUser[_camelCase(this.cloneField.name)] = value;
        }
      },
      showErrors() {
        if (!this.$refs.field) {
          return true;
        }
        return this.$refs.field.showErrors();
      },
      showEmailFieldError(msg) {
        if (this.$refs.field) {
          this.$refs.field.showEmailError(msg);
        }
      },
      updateFormField(params) {
        this.$emit("update", params);
      },
      openMfaPhoneModal() {
        this.$emit('open-modal');
      },
      deleteValue(value) {
        this.$emit('delete', this.cloneField, value);
      },
    },
  };
</script>

<style lang="scss" scoped>
.required:after {
  content:" *";
  color: red;
}

.static-field {
  background-color: $themed-lighter;
  font-size: 0.875rem;

  label {
    color: $cyan;
  }
}
</style>
