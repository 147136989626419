import http from 'common/http';

export default {
  computed: {
    isGenuityDomain() {
      return window.location.host.includes('genuity.com') || window.location.host.includes('genuity-staging.com');
    },
  },
  methods: {
    getLogoUrl() {
      if (this.customDomain?.logoUrl && !this.isGenuityDomain) {
        return this.customDomain.logoUrl;
      }
      return this.company?.url;
    },
    updateFavicon() {
      if (this.customDomain?.faviconUrl && !this.isGenuityDomain) {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'icon';
        link.href = this.customDomain.faviconUrl;
        document.head.appendChild(link);
      }
    },
    fetchCustomDomain() {
      http
        .get('/custom_domains.json')
        .then(res => {
          this.setCustomDomain(res.data.domain);
          this.updateFavicon();
        })
        .catch(() => {
          this.emitError('There was an error fetching your custom domain. Please refresh the page and try again.');
        });
    },
  },
};
