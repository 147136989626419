<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="faqObj">
    <div
      class="row"
      :class="{'mb-2': compact, 'mb-3': !compact}"  
    >
      <div class="col-12">
        <div class="d-flex align-items-center">
          <h5
            class="text-themed-base mb-1 mr-4"
            :class="{
              'truncate article-title-width-restricted': truncateTitle,
              'h4': !compact,
            }"
            :data-tc-view-article="faqObj.questionBody"
            v-html="sanitizeHTML(faqObj.questionBody)"
          />
        </div>
      </div>
    </div>
    <p
      class="mt-1 p--responsive text-secondary text-wrap article-description"
      :class="{
        'truncate-body article-title-width-restricted': truncateBody,
        'not-as-small': compact,
        'base-font-size': !compact,
      }"
      :data-tc-otp-article-body="faqObj.answerBody"
      v-html="sanitizeHTML(faqObj.answerBody)"
    />
  </div>
</template>

<script>
import strings from 'mixins/string';

export default {
  mixins: [strings],
  props: {
    faqObj: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    truncateTitle: {
      type: Boolean,
      default: true,
      required: false,
    },
    truncateBody: {
      type: Boolean,
      default: false,
      required: false,
    },
    compact: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .article-title-width-restricted {
    max-width: 36rem;
  }

  .truncate-body {
    max-height: 3.75rem;
    overflow: hidden;
  }

  .badge-bg {
    background-color: $info;
    background-color: rgba($info, .8);
    border: 1px solid $info;
  }

  .article-description /deep/ {
    ul {
      list-style-type: disc;
      padding-left: 1rem;
    }
  }
</style>
