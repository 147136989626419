<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="align-items-center row">
          <div class="col-12">
            <h6
              v-tooltip="{
                content: articleObj.title,
                show: (articleObj.title && articleObj.title.length > 20  && hoveredIndex === articleObj.title),
                trigger: 'manual'
              }"
              class="truncate text-themed-base mb-0 mr-4 article-title"
              :data-tc-view-title="articleObj.title"
              @mouseover="hoveredIndex = articleObj.title"
              @mouseleave="hoveredIndex = null"
            >
              {{ articleObj.title }}
            </h6>
          </div>
        </div>
        <div
          v-if="!articleObj.tags.length"
          class="mb-2"
        />
      </div>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <p
      class="mt-1 small p--responsive truncate-body text-muted text-wrap article-description"
      :data-tc-view-article-body="articleObj.body"
      v-html="sanitizeHTML(articleObj.body)"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div
      v-if="articleObj.tags.length"
      class="row my-2 ml-0"
    >
      <div
        v-for="(tag, index) in articleObj.tags"
        :key="index"
        class="badge bg-info text-white mr-2 tags--border col-auto mt-1"
        :data-tc-view-article-tags="tag"
      >
        {{ tag }}
      </div>
    </div>
    <div
      class="badge box-badge--company text-muted"
      :data-tc-view-article-category="articleObj.category"
    >
      {{ articleObj.category }}
    </div>

    <div
      v-if="!hideAdminElements && articleObj.public"
      v-tooltip="'Viewable in your public Help Center'"
      class="badge box-badge bg-muted text-white"
      data-tc-article-is-public
    >
      Public
    </div>

    <span class="d-flex text-muted small position-absolute article-date">
      <span
        v-if="!hideAdminElements"
        class="d-flex"
      >
        <avatar
          v-tooltip="`Created by: ${articleObj.authorFullName}`"
          class="d-inline-block align-middle mr-2 logo-outline"
          :size="18"
          :src="articleObj.avatarThumbUrl"
          :username="articleObj.authorFullName"
          :data-tc-view-created-by="articleObj.authorFullName"
        />
      </span>

      {{ updatedAt(articleObj) }}
    </span>
  </div>
</template>

<script>
  import { Avatar } from 'vue-avatar';
  import strings from 'mixins/string';
  import Articles from "mixins/articles";
  import vClickOutside from 'v-click-outside';

  export default {
    directives: {
      clickOutside: vClickOutside.directive,
    },
    components: {
      Avatar,
    },
    mixins: [strings, Articles],
    props: {
      articleObj: {
        type: Object,
        default: () => {},
      },
      activeArticleActionsSlug: {
        type: String,
        default: '',
      },
      hideAdminElements: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showArticleActions: false,
        hoveredIndex: null,
      };
    },
    watch: {
      activeArticleActionsSlug() {
        if (this.activeArticleActionsSlug !== this.articleObj.slug) {
          this.showArticleActions = false;
        }
      },
    },
    methods: {
      emitEdit() {
        this.closeArticleActions();
        this.$emit('edit-article', this.articleObj);
      },
      emitDelete() {
        this.closeArticleActions();
        this.$emit('delete-article', this.articleObj);
      },
      closeArticleActions() {
        this.showArticleActions = false;
      },
      toggleArticleActions() {
        if (this.showArticleActions) {
          this.closeArticleActions();
        } else {
          this.showArticleActions = true;
          this.$emit('open-article-actions', this.articleObj.slug);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  /deep/ img {
    margin-top: -1rem;
    max-height: 2rem;
    width: auto;
  }

  .box-badge--company {
    bottom: -1px;
    left: -1px;
  }
</style>

