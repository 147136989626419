<template>
  <div>
    <input
      :id="`field${field.id}`"
      ref="input"
      :value="valueStr"
      class="hidden"
      :required="required"
    >
    <trix-vue
      ref="trixEditor"
      :key="trixKey"
      class="description-field px-0"
      style="min-height: 100px; max-width: 73.5rem;"
      attachable-type="CustomFormValue"
      :input-id="`field${field.id}`"
      :module-type="getCompanyModule"
      value="valueStr"
      @input="emitValue"
      @update-uploading-status="updateUploadingStatus"
      @handle-attachment-add="handleAttachmentAdd"
      @handle-attachment-remove="handleAttachmentRemove"
    />
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
  import _get from 'lodash/get';
  import fieldViewerInput from 'mixins/field_viewer_input';
  import permissionsHelper from 'mixins/permissions_helper';
  import { watchUnusedAttachments } from 'mixins/trix_vue_helper';
  import TrixVue from "../../../trix_vue.vue";
  
  export default {
    components: {
      TrixVue,
    },
    mixins: [fieldViewerInput, permissionsHelper, watchUnusedAttachments],
    props: {
      field: {
        type: Object,
        default: () => {},
      }, 
      attachmentIds: {
        type: Array,
        default: () => [],
      },
      value: {
        type: String,
        default: "",
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        errors: null,
        valueStr: _get(this, "value", ""),
        doc: null,
        trixKey: 0,
        localAttachmentIds: [...this.attachmentIds],
      };
    },
    watch: {
      value(newVal) {
        // Since valueStr is not updated on user input, then we can assume that
        // if `value` and `valueStr` match the change is from the data source and
        // we can safely update the trixKey to ensure Trix rendering JS is reran.
        if (newVal === this.valueStr) {
          this.trixKey += 1;
        }
      },
      attachmentIds(newIds) {
        this.localAttachmentIds = [...newIds];
      },
    },
    methods: {
      onWorkspaceChange() {
        this.setValueStr();
      },
      updateUploadingStatus(status) {
        this.$emit('attachment-uploading-status', status);
      },
      handleAttachmentAdd(data) {
        this.localAttachmentIds.push(data.attachment.id);
        this.$emit('update:attachmentIds', this.localAttachmentIds);
      },
      handleAttachmentRemove(attachmentId) {
        const idx = this.localAttachmentIds.indexOf(attachmentId);
        if (idx > -1) {
          this.localAttachmentIds.splice(idx, 1);
        }
        this.$emit('update:attachmentIds', this.localAttachmentIds);
      },
      emitValue(value) {
        const parser = new DOMParser();
        this.doc = parser.parseFromString(value, 'text/html');
        this.removeElement(".attachment__caption-editor");
        this.removeElement(".attachment__caption-editor.trix-autoresize-clone");
        this.removeElement(".attachment__progress");
        this.removeElement(".trix-button.trix-button--remove");
        const innerHTML = this.doc.getElementsByTagName('body')[0].innerHTML;
        this.errors = null;
        this.$emit("input",innerHTML);
      },
      showErrors() {
        let msg = null;
        if (!this.required && this.moduleName !== "automatedTasks") {
          return false;
        }
        if (!this.value || this.value.length === 0) {
          msg = `Please enter a ${this.field.label}`;
          this.$refs.input.setCustomValidity(msg);
          this.errors = msg;
        } else {
          this.$refs.input.setCustomValidity('');
          this.errors = null;
        }
        return !this.errors;
      },
      removeElement(name) {
        if (this.doc.querySelector(name)) {
          this.doc.querySelector(name).remove();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .trixWrapper /deep/ {
    trix-toolbar {
      margin-top: 0.25rem;
      padding: 0 0.75rem;
    }

    trix-editor {
      border-radius: 0;
      border: 0;
      border-top: 1px solid $themed-very-fair;
      padding: 0.5rem 0.75rem 0;
    }
  }
  .description-field {
    min-height: 12rem;
  }
</style>
