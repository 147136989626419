<template>
  <div v-if="field">
    <slot name="label" />
    <div>
      <div
        v-for="selected in selectedOptions"
        :key="selected.id"
        :data-id="selected.id"
        class="mb-1 row"
      >
        <div class="col-auto d-inline pr-0">
          <slot
            name="image"
            :option="selected"
          />
        </div>
        <div class="col pl-2">
          <a
            class="avatar-name d-block not-as-small"
            href="#"
            target="_blank"
            :data-tc-name-selected="selected.name"
            @click="$emit('open', selected)"
          >
            {{ selected.name }}
          </a>
        </div>
        <div
          class="col"
          @click="remove(selected)"
        >
          <i
            class="nulodgicon-trash-b remove-link float-right p-1"
            style="font-size: 0.85rem;"
          />
        </div>
      </div>
      <input
        ref="input"
        type="text"
        class="hidden"
        name="asset_list"
        :required="required"
        :value="value"
      >
      <multi-select
        class="w-100"
        label="name"
        track-by="id"
        :placeholder="placeholder"
        :internal-search="false"
        :options="filteredOptions"
        :show-labels="false"
        :close-on-select="true"
        :allow-empty="true"
        :multiple="false"
        :taggable="false"
        :disabled="disabled"
        :loading="showMoreLoading"
        @select="selectOption"
        @open="openOptions"
        @search-change="asyncFind"
      >
        <span
          v-if="field.fieldAttributeType == 'people_list'"
          slot="noResult"
          @click="openNewUserModal"
        >
          Teammate not found, would you like to add them?
        </span>
        <template
          slot="singleLabel"
          slot-scope="props"
        >
          <div class="align-items-center d-flex">
            <div
              class="d-inline-block"
            >
              <slot
                name="image"
                :option="props.option"
              />
            </div>
            <div class="pl-2 d-inline-block col-10">
              <span class="avatar-name d-block not-as-small">
                {{ props.option.name }}
              </span>
            </div>
            <div
              v-if="props.option.item"
              class="col pl-0 d-inline"
            >
              <i
                class="nulodgicon-link-1 text-info bg-light rounded-circle float-right ml-2 p-1"
                style="font-size: 1.25rem;"
              />
            </div>

            <div
              v-if="props.option.id"
              class="col-auto d-inline"
            />
            <div
              v-if="props.option.item"
              class="col pl-0 d-inline"
            >
              <span class="avatar-name not-as-small">
                {{ props.option.item.name }}
              </span>
            </div>
          </div>
        </template>

        <template
          slot="option"
          slot-scope="props"
        >
          <div class="row align-items-center">
            <div
              v-if="props.option.name"
              class="col-auto d-inline pr-0"
            >
              <slot
                name="image"
                :option="props.option"
              />
            </div>

            <div class="col d-inline pl-2 item-name">
              <span
                class="avatar-name not-as-small"
              >
                {{ props.option.name }}
              </span>
            </div>
          </div>
        </template>
        <template
          v-if="displayShowMore"
          slot="afterList"
        >
          <div
            class="not-as-small py-3 cursor-pointer text-center"
            style="padding-left: .75rem!important;"
            @click="showMore"
          >
            <a href="#">+ Show More</a>
          </div>
        </template>
      </multi-select>
      <span
        v-if="errors"
        class="form-text small text-danger"
      >
        {{ errors }}
      </span>
    </div>
    <sweet-modal
      ref="newUserModal"
      title="Add a new Teammate"
    >
      <company-user-custom-form-viewer
        v-if="showAddStaffModal"
        render-from-modal
        @new-user="newUser"
      />
    </sweet-modal>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import http from "common/http";
import _get from "lodash/get";
import _map from 'lodash/map';
import _compact from "lodash/compact";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { SweetModal } from 'sweet-modal-vue';
import _debounce from "lodash/debounce";
import customFormLists from "mixins/custom_forms/lists";
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';

export default {
  components: {
    MultiSelect,
    SweetModal,
    CompanyUserCustomFormViewer: () => import('components/shared/custom_forms/custom_form_viewer/company_user_custom_form_viewer.vue'),
  },
  mixins: [customFormLists, fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
    },
    optionsUrl: {
      type: String,
    },
    pluralName: {
      type: String,
    },
    singleName: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      errors: null,
      query: null,
      offset: 0,
      showMoreLoading: false,
      disableShowMore: false,
      dropdownOpened: false,
      optionsMap: {},
      idsToRemove: [],
      idsToAdd: [],
      showAddStaffModal: false,
      isHelpCenterPortal: window.location.pathname.startsWith('/help_center'),
    };
  },
  computed: {
    ...mapGetters([
      'helpdeskEmails',
      'companyUserOptions',
    ]),
    displayShowMore() {
      return !this.disableShowMore;
    },
    placeholder() {
      const placeholderText = {
        'people_list' : 'Type email or search/select',
        'asset_list' : 'Search or select asset(s)',
        'contract_list': 'Search or select contract(s)',
        'location_list': 'Search or select locations(s)',
        'telecom_list' : 'Search or select telecom service(s)',
        'vendor_list' : 'Search or select vendor(s)',
      };
      return this.field.placeholder || placeholderText[this.field.fieldAttributeType];
    },
    selectedOptions() {
      if (!this.value || this.value.length === 0) {
        return [];
      }
      return _compact(this.value.map((v) => this.optionsMap[v]));
    },
  },
  methods: {
    ...mapMutations(["removeValueToRemove"]),
    ...mapActions(["fetchCompanyUserOptions"]),

    onWorkspaceChange() {
      this.fetchCompanyUserOptions({ 
        archived_users_only: true,
        is_help_center_portal: this.isHelpCenterPortal, 
      });
      this.setSmartListFieldValue();
      this.loadSelectedOptions();
    },
    showMore() {
      if (!this.options || this.options.length === 0) {
        this.offset = 0;
      } else {
        this.offset += 20;
      }
      this.loadMore();
    },
    asyncFind: _debounce(function (query) {
      if (this.query !== query) {
        this.query = query;
        this.offset = 0;
        this.loadMore();
      }
    }, 700),
    openOptions() {
      this.disableShowMore = true;
      this.options = [];
      this.offset = 0;
      this.loadMore();
    },
    loadSelectedOptions() {
      const ids = [];
      if (!this.value) {
        return [];
      }
      for (let idx = 0; idx < this.value.length; idx += 1) {
        if (this.value[idx]) {
          ids.push(this.value[idx]);
        }
      }
      if (ids.length === 0) {
        return null;
      }
      const params = { 
        includes: ids,
        is_help_center_portal: this.isHelpCenterPortal,
        field_id: this.field.id,
      };
      if (this.$route.name.includes('automated-task')) {
        params.archived = 'all';
      }
      this.fetchSelectedOptions(params);
      return null;
    },
    fetchSelectedOptions(params) {
      http
        .get(this.optionsUrl, { params })
        .then((res) => {
          const myMap = {};
          res.data.forEach((d) => {
            myMap[d.id] = d;
          });
          this.optionsMap = myMap;
        })
        .catch(() => {
          this.emitError(
            `Sorry, there was an error loading ${this.pluralName}. Please try again later.`
          );
      });
    },
    loadMore() {
      const params = { offset: this.offset, limit: 20 };
      if (this.query && this.query.length > 0) {
        params.query = this.query;
      }
      const ids = [];
      if (this.value) {
        for (let idx = 0; idx < this.value.length; idx += 1) {
          if (this.value[idx]) {
            ids.push(this.value[idx]);
          }
        }
      }
      params.excludes = ids;
      params.field_id = this.field.id;
      if (this.field.options && this.field.options.length) {
        params.group_contributor_ids = _map(this.field.options, 'contributorId');
      }
      this.fetchOptions(params);
    },
    fetchOptions(params) {
      http
        .get(this.optionsUrl, { params })
        .then((res) => {
          this.handleOptionResults(this.offset, res.data);
        })
        .catch(() => {
          this.emitError(
            `Sorry, there was an error loading ${this.pluralName}. Please try again later.`
          );
        });
    },
    handleOptionResults(offset, data) {
      this.showMoreLoading = false;
      if (offset === 0) {
        this.options = data;
      } else {
        this.options = this.options.concat(data);
      }
      if (data.length < 20) {
        this.disableShowMore = true;
      } else {
        this.disableShowMore = false;
      }
    },
    remove(selected) {
      const ids = [];
      for (let i = 0; i < this.value.length; i += 1) {
        const v = this.value[i];
        if (v !== selected.id) {
          ids.push(v);
        }
      }
      for (let i = 0; i < this.selectedOptions.length; i += 1) {
        const v = this.selectedOptions[i];
        if (v.id === selected.id) {
          this.$emit('delete',this.selectedOptions[i].id);
          this.selectedOptions.splice(i, 1);
          break;
        }
      }

      this.$emit("input", ids);
    },
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      let myValues = _get(this, "value", []);
      if (myValues instanceof Array) {
        myValues = _compact(myValues);
        if (myValues.length === 0) {
          msg = `Please select ${this.singleName}`;
          this.$refs.input.setCustomValidity(msg);
          this.errors = msg;
        } else {
          this.$refs.input.setCustomValidity("");
          this.errors = null;
        }
      } else if (!myValues) {
        msg = `Please select ${this.singleName}`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity("");
        this.errors = null;
      }
      return !this.errors;
    },
    selectOption(option) {
      let ids = [];
      if (!this.isCustomEmail(option.email || option.id)) {
        if (!this.isArchivedUser(option.email || option.id)) {
          if (this.value) {
            ids = this.value.filter(item => item !== undefined && item !== null);
          }
          this.optionsMap[option.id] = option;
          ids.push(option.id);
          this.errors = null;
          this.$emit("input", ids);
        } else {
          this.emitError('Sorry, an archived user with this email already exists. Please select a different email address.');
        }
      } else {
        this.emitError('Sorry, this email is already used in custom forms. Please select a different email address.');
      }
    },
    isCustomEmail(currentUserEmail) {
      return this.helpdeskEmails && this.helpdeskEmails.includes(currentUserEmail);
    },
    isArchivedUser(currentUserEmail) {
      return this.companyUserOptions && this.companyUserOptions.find(u => u.email === currentUserEmail);
    },
    openNewUserModal() {
      this.showAddStaffModal = true;
      this.$refs.newUserModal.open();
    },
    newUser(user) {
      const option = {
        avatarThumbUrl: user.avatarThumbUrl, 
        email: user.user.email, 
        id: user.contributorId, 
        name: user.user.fullName,
        rootId: user.id,
        type: "CompanyUser",
      };

      this.selectOption(option);
      this.$refs.newUserModal.close();
      this.showAddStaffModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .remove-link {
    color: $themed-fair;

    &:hover {
      color: $danger;
    }
  }
  .multiselect /deep/ {
    .multiselect__tags {
      border-radius: $border-radius;
      border-color: $themed-fair;
    }
  }

  .multiselect__tags {
    border-color: $themed-fair;
  }

  .multiselect__tag {
    overflow: inherit !important;
    white-space: unset;
  }

  .multiselect__option--group {
    background: #0A1E40!important;
    color: $white!important;
  }

  .avatar-name {
    color: $themed-base;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
    word-break: break-word;

    .multiselect__option--highlight & {
      color: white;
    }
  }

  .avatar-email {
    color: $text-muted;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .multiselect__option--highlight & {
      color: white;
    }
  }

  .tips-icon {
    width: 1rem;
  }

  .universal-link__badge {
    background: $themed-very-fair;
    border: 1px solid $themed-fair;
    border-radius: 13px;
    color: $themed-dark!important;
    margin-left: 1rem;
    padding: .125rem .5rem .125rem .25rem;
  }

  .disabledLink {
    .universal-link__badge {
      background-color: $themed-light;
    }
  }

  .item-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 275px;
    display: inline-block;
    width: 100%;
  }

  .fade-in {
    animation: slight-fade-out 2s reverse;
  }
  .fade-out {
    animation: slight-fade-out 2s;
  }

  @keyframes slight-fade-out {
    from {
      opacity: 1.0;
    }

    30% {
      opacity: 0.4;
    }

    60% {
      opacity: 1.0;
    }

    to {
      opacity: 0.0;
      display: none;
    }
  }
</style>
