var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.articleObj)?_c('div',[_c('div',{staticClass:"row",class:{'mb-2': _vm.compact, 'mb-3': !_vm.compact}},[_c('div',{staticClass:"col-12"},[(!_vm.hideTitle)?_c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"text-themed-base mb-1 mr-4",class:{
            'truncate article-title-width-restricted': _vm.truncateTitle,
            'h4': !_vm.compact,
          },attrs:{"data-tc-view-article":_vm.articleObj.title}},[_vm._v("\n          "+_vm._s(_vm.articleObj.title)+"\n        ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.articleObj.tags),function(tag){return _c('span',{key:tag,staticClass:"badge badge-bg text-white mr-2",attrs:{"data-tc-view-article-tags":tag}},[_vm._v("\n        "+_vm._s(tag)+"\n      ")])})],2)]),_vm._v(" "),_c('p',{staticClass:"mt-1 p--responsive text-secondary text-wrap article-description",class:{
      'truncate-body': _vm.truncateBody,
      'not-as-small': _vm.compact,
      'base-font-size': !_vm.compact,
    },attrs:{"data-tc-otp-article-body":_vm.articleObj.body},domProps:{"innerHTML":_vm._s(_vm.sanitizeHTML(_vm.articleObj.body))}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }