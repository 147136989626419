<template>
  <div 
    v-if="!isValidWorkspace"
    class="mx-auto pb-5 single-column-form-wrap mt-5"
  >
    <div class="box px-5 box__inner">
      <div class="mt-3">
        <h6>
          We're sorry, it appears you've entered an invalid URL for our FAQ page.
        </h6>
        <p class="not-as-small">
          Please double-check the URL or navigate back to FAQs by clicking <a href="/faqs">here.</a> 
          If you need further assistance, feel free to contact our support team. Thank you for your understanding.
        </p>
      </div>
    </div>
  </div>
  <div v-else>
    <help-center-header
      :is-submit-request-route-disabled="hasNoFormInWorkspace"
      :selected-workspace="selectedWorkspaceItem"
    >
      <div class="position-relative hc-text-header">
        <h1 class="mt-5 font-weight-bold mb-4 help-center-title">
          <img
            src="~/images/faq.png"
            height="96"
            class="mt-n5 mb-n3 ml-n6"
          >
          FAQs
        </h1>
        <search-bar
          class="mb-3"
          faq-search-enabled
          suggested-text="Search our FAQs..."
        />
        <div class="big mb-6">
          Quickly find answers to questions we've all asked.
        </div>
      </div>
    </help-center-header>
    <div class="pb-7 position-relative border-b-30 readable-length--large mx-auto ">
      <div class="row">
        <div class="col-8 pr-0">
          <div class="box px-5 pt-5 mt-n6 box--natural-height pb-0 help-center-main-content">
            <div class="box__inner">
              <div class="d-flex align-items-center mt-n3 mb-4 justify-content-between">
                <div class="d-flex">
                  <span class="smallest text-muted">Help Desk Workspace:</span>
                  <multi-select
                    name="workspaces"
                    placeholder="Select Workspace"
                    select-label=""
                    deselect-label=""
                    track-by="name"
                    label="name"
                    :value="selectedWorkspace"
                    :allow-empty="true"
                    :multiple="false"
                    :options="workspaces"
                    @select="selectWorkspace"
                  />
                </div>
                <div 
                  class="d-flex align-items-start cursor-pointer" 
                  @click="$router.push(`/workspaces/${selectedWorkspace.id}`);"
                >
                  <i class="nulodgicon-arrow-left-c white mr-2"/>
                  <span>Back</span>
                </div>
              </div>
              <div class="d-flex flex-column pl-0 mb-4">
                <!-- Help Center TODO: Add Categories and update to match markup/classes from Knowledge Base Articles -->
                <!-- <h5 class="font-weight-normal">
                  What kind of issue are you experiencing?
                </h5> -->
                <!-- <div 
                  v-if="computedUsedCategories"
                  class="mb-n2 form-group"
                >
                  <label
                    v-for="(category, index) in computedUsedCategories"
                    :key="`${index}-category`"
                    class="border-300 mr-2 mb-2 cursor-pointer help-center-custom-pill truncate"
                    :class="[
                      isInSelectedCategories(category) ? 'text-white' : 'bg-white text-black', 
                      computedUsedCategories.length <= categoryResizeThreshold ? 'py-2 px-3' : 'py-1 px-2', 
                      'border-300 mr-2 mb-2 cursor-pointer help-center-custom-pill truncate']"
                    :style="{
                      backgroundColor: helpCenterHyperlinksColor,
                    }"
                    @click="selectCategory(category)"
                  >
                    {{ category}}
                  </label>
                </div>
                <pulse-loader
                  v-else
                  color="#0d6efd"
                  size="0.5rem"
                  :loading="true"
                /> -->
              </div>
              <div>
                <hr class="my-2" >
                <section
                  v-if="publicFaqs && publicFaqs.length > 0"
                  class="pb-5 mt-4"
                >
                  <pulse-loader
                    v-if="loading"
                    size="0.5rem"
                    :color="helpCenterHyperlinksColor"
                    :loading="true"
                  />
                  <div
                    v-for="(faq, index) in publicFaqs"
                    :key="`${index}-fAQ`"
                    class="faq-list-item py-1 mb-4"
                    tabindex="0"
                    @keyup.enter="selectFaq(faq)"
                    @click="selectFaq(faq)"
                  >
                    <faq-item
                      class="clickable"
                      :class="{'clickable-hover p-2 mx-n2 mt-n1 rounded': false}"
                      :faq-obj="faq"
                      :truncate-title="false"
                      :truncate-body="true"
                      compact
                    />
                    <i class="open-faq-icon nulodgicon-chevron-right d-none btn btn-info text-white btn-xs rounded-circle" />
                  </div>
                </section>
                <pulse-loader
                  v-else-if="loading"
                  class="py-2"
                  size="0.5rem"
                  :color="helpCenterHyperlinksColor"
                  :loading="true"
                />
                <div
                  v-else
                  class="my-5 text-center w-100"
                  data-tc-no-faq
                >
                  <h4 class="text-secondary">
                    No FAQ found.
                  </h4>
                </div>
                <!-- Help Center TODO: Add pagination -->
                <!-- <div
                  v-if="publicFaqsPageCount > 1"
                  class="row mt-sm-1 mt-md-2"
                >
                  <div class="col-12 d-flex align-items-center justify-content-end">
                    <paginate
                      ref="paginate"
                      class="mb-0"
                      :click-handler="pageSelected"
                      :container-class="'pagination pagination-sm'"
                      :next-class="'next-item'"
                      :next-link-class="'page-link'"
                      :next-text="'Next'"
                      :page-class="'page-item'"
                      :page-count="publicFaqsPageCount"
                      :page-link-class="'page-link'"
                      :prev-class="'prev-item'"
                      :prev-link-class="'page-link'"
                      :prev-text="'Prev'"
                      :selected="publicFaqsPage"
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col text-right pl-0">
          <div class="mt-6 mx-n7 ">
            <img 
              class="help-center-image"
              src="~/images/help_center.png"
            >
          </div>
        </div>
      </div>
    </div>

    <faq-modal
      v-if="isFaqModalOpened"
      ref="faqShowModal"
      :faq="helpCenterSelectedFaq"
    />

    <notifications position="bottom right" />
  </div>
</template>
<script>
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import permissionsHelper from 'mixins/permissions_helper';
  import _debounce from 'lodash/debounce';
  import MultiSelect from 'vue-multiselect';
  import FaqItem from "components/help_tickets/faqs/faq_help_center_portal.vue";
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import FaqModal from 'components/help_tickets/faqs/faq_modal.vue';
  import HelpCenterHeader from './help_center_header.vue';
  import SearchBar from './search_bar.vue';

  export default {
    components: {
      PulseLoader,
      FaqItem,
      FaqModal,
      HelpCenterHeader,
      SearchBar,
      MultiSelect,
    },
    mixins: [helpCenterPortalHelper, permissionsHelper],
    props: ['workspaces'],
    data() {
      return {
        selectedWorkspace: getWorkspaceFromStorage(),
        selectedCategories: ['Everything'],
        categoryResizeThreshold: 8,
        searchString: '',
        finishSearch: false,
        isFaqModalOpened: false,
        isValidWorkspace: true,
      };
    },
    computed: {
      ...mapGetters([
        'helpCenterBGColor',
        'helpCenterHyperlinksColor',
        'publicFaqs',
        'loading',
        // Help Center TODO: Add Categories
        // 'categories',
        'helpCenterSelectedFaq',
      ]),
      // Help Center TODO: Add Categories
      // computedUsedCategories() {
      // const categoryNames = this.categories.map(category => category.name);
      //   return ['Everything', ...categoryNames];
      // },
      // filteredCategories() {
      //   return  this.selectedCategories.includes('Everything') ? null : this.selectedCategories;
      // },
    },
    watch: {
      helpCenterSelectedFaq() {
        this.isFaqModalOpened = true;
        this.$nextTick(() => {
          if (this.$refs.faqShowModal) {
            this.$refs.faqShowModal.open();
          }
        });
      },
    },
    mounted() {
      const workspace = this.workspaces.find(w => w.id === parseInt(this.$route.params.workspace_id, 10));
      if (!workspace?.id) {
        this.isValidWorkspace = false;
        return;
      }
      this.fetchHelpCenterColors().then(() => {
        // Help Center TODO: Add Categories
        // this.fetchCategories({ faqs: true });
        this.setLoading(true);
        this.loadFaqs();
      });
    },
    methods: {
      ...mapActions([
        // Help Center TODO: Add Categories
        // 'fetchCategories',
        'fetchPublicFaqs',
        'fetchHelpCenterColors',
      ]),
      ...mapMutations([
        // Help Center TODO: Add Categories
        // 'setPublicFaqs',
        // 'setPublicFaqsCategories',
        'setHelpCenterSelectedFaq',
        'setLoading',
      ]),
      // Help Center TODO: Add Categories
      // isInSelectedCategories(category) {
      //   return this.selectedCategories.includes(category);
      // },
      selectWorkspace(workspace){
        this.selectedWorkspace = workspace;
        setWorkspaceToStorage(workspace);
        this.fetchHelpCenterColors().then(() => {
          // Help Center TODO: Add Categories
          // this.fetchCategories({ faqs: true });
          this.setLoading(true);
          this.loadFaqs();
          this.$router.push(`/faqs/workspaces/${this.selectedWorkspace.id}`);
        });
      },
      selectFaq(faq) {
        this.setHelpCenterSelectedFaq(faq);
      },
      // selectCategory(category) {
      //   if (category === 'Everything') {
      //     this.selectedCategories = ['Everything'];
      //   } else if (!this.selectedCategories.includes(category)) {
      //     if (this.selectedCategories.includes('Everything')) {
      //       this.selectedCategories.splice(this.selectedCategories.indexOf('Everything'), 1);
      //     }
      //     this.selectedCategories.push(category);
      //   } else {
      //     const index = this.selectedCategories.indexOf(category);
      //     if (index !== -1) {
      //       this.selectedCategories.splice(index, 1);
      //     }
      //   }
      //   if (this.selectedCategories.length === 0) {
      //     this.selectedCategories = ['Everything'];
      //   }
      //   this.setPublicFaqsCategories(this.filteredCategories);
      //   this.loadFaqs();
      // },
      loadFaqs: _debounce(function loadFaqs(closeDropdown) {
        this.finishSearch = closeDropdown;
        // Help Center TODO: Add pagination
        // this.setPublicFaqsPage(0);
        this.fetchPublicFaqs({ searchTerms: this.searchString});
      }, 500),

      // pageSelected(p) {
      //   this.setPublicFaqsPage(p - 1);
      //   this.fetchPublicFaqs({ search: this.search, category: this.filteredCategories });
      // },
    },
  };
</script>

<style lang="scss" scoped>
  $after-effect-width: 0.25rem;
  $after-effect-offset: 2.25rem;
  $open-icon-font-size: 1.25rem;

  .faq-list-item {
    position: relative;

    .open-faq-icon {
      font-size: $open-icon-font-size;
      position: absolute;
      right: -1.5rem;
      top: calc(50% - (#{$open-icon-font-size}/2));
    }

    &:after {
      background-color: $color-info;
      border-radius: 0.125rem;
      bottom: 0;
      content: "";
      left: -$after-effect-offset;
      height: 100%;
      position: absolute;
      transition: $transition-base;
      transform: scaleY(0);
      transform-origin: center;
      width: $after-effect-width;
    }

    &:hover { 
      &:after {
        transform: scaleY(1);
      }

      .open-faq-icon {
        display: block !important;
      }
    }
  }
</style>
