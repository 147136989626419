<template>
  <div
    v-show="!isLoadingHelpCenterDesign"
    :style="helpCenterColorSettingsVariables"
  >
    <header 
      v-if="isFaqs || !helpCenterModernDesignEnabled"
      class="site-header w-100 clearfix d-flex"
    >
      <div class="site-header__left helpcenter-menu">
        <div
          v-if="logoPresent && companyNamePresent"
          class="company-drawer d-flex align-items-center"
        >
          <div
            height="40"
            class="company-drawer__logo logo-outline pl-4"
            :style="backgroundImageCss"
          />
          <div class="company-drawer__info ml-2 align-baseline mt-1">
            <h6 class="company-drawer__name mb-0">
              {{ companyName }}
            </h6>
          </div>
        </div>
        <img
          v-else-if="!logoPresent && !companyNamePresent"
          src="~images/logo.png"
          height="50"
        >
        <div v-else-if="companyNamePresent">
          <h6 class="ml-5 mt-3">
            {{ companyName }}
          </h6>
        </div>
      </div>
    </header>

    <router-view
      v-if="workspaces"
      ref="component"
      :workspaces="workspaces"
    />
    <notifications position="bottom right" />
  </div>
</template>

<script>
  import fontColorContrast from 'font-color-contrast';
  import colorConvert from 'color-convert';
  import http from 'common/http';
  import _get from 'lodash/get';
  import strings from 'mixins/string';
  import permissionsHelper from 'mixins/permissions_helper';
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import customDomainHelper from 'mixins/custom_domain_helper';
  import { mapMutations, mapActions, mapGetters } from 'vuex';

  export default {
    mixins: [strings, permissionsHelper, helpCenterPortalHelper, customDomainHelper ],
    data() {
      return {
        selectedForms: null,
        customFormOptions: null,
        optionsLoading: true,
        workspaces: null,
        routesToFilterWorkspaces : [
          'workspaces', 
          'forms',
          'form',
        ],
        workspaceSelectionRouteNames: [
          'workspaces',
          'faqsWorkspaces',
          'articlesWorkspaces',
        ],
        alreadySent: false,
      };
    },
    computed: {
      ...mapGetters([
        'isLoadingHelpCenterDesign',
        'helpCenterModernDesignEnabled',
        'helpCenterBGColor',
        'helpCenterHyperlinksColor',
        'customDomain',
      ]),
      company() {
        return getCompanyFromStorage() || this.customDomain?.company;
      },
      logoPresent() {
        return this.company && this.company.url && this.company.url.length > 0;
      },
      companyName() {
        return _get(this, 'company.name');
      },
      companyNamePresent() {
        return _get(this, 'company.name', "").length > 0;
      },
      backgroundImageCss() {
        return {"background-image": `url("${this.getLogoUrl()}")`};
      },
      isFaqs() {
        return this.$route.name === 'faqs';
      },
      headerTextColor() {
        // HTML Color input always saves a HEX code, so we don't need to worry about any conversion
        let contrastColor = fontColorContrast(this.helpCenterBGColor, 0.6); 
        if (contrastColor === "#000000") {
          const bgHSL = colorConvert.hex.hsl(this.helpCenterBGColor);
          bgHSL[2] = 12;
          contrastColor = `#${colorConvert.hsl.hex(bgHSL)}`;
        }
        return contrastColor;
      },
      linkColorContrastHex() {
        return fontColorContrast(this.helpCenterHyperlinksColor, 0.6);
      },
      buttonTextColor() {
        let contrastColor = this.linkColorContrastHex;
        if (contrastColor === "#000000") {
          const bgHSL = colorConvert.hex.hsl(this.helpCenterHyperlinksColor);
          bgHSL[2] = 12;
          contrastColor = `#${colorConvert.hsl.hex(bgHSL)}`;
        }
        return contrastColor;
      },
      buttonHoverColor() {
        const bgHSL = colorConvert.hex.hsl(this.helpCenterHyperlinksColor);
        bgHSL[2] = Math.max(bgHSL[2] - 8, 0);
        return `#${colorConvert.hsl.hex(bgHSL)}`;
      },
      bodyLinkColor() {
        const contrastColor = this.linkColorContrastHex;
        if (contrastColor === "#000000") {
          const linkHSL = colorConvert.hex.hsl(this.helpCenterHyperlinksColor);
          linkHSL[2] = 100 - linkHSL[2];
          return `#${colorConvert.hsl.hex(linkHSL)}`;
        }
        return this.helpCenterHyperlinksColor;
      },
      helpCenterColorSettingsVariables() {
        return {
          "--hc-header-text": this.headerTextColor,
          "--hc-button-bg": this.helpCenterHyperlinksColor,
          "--hc-button-hover-bg": this.buttonHoverColor,
          "--hc-button-text": this.buttonTextColor,
          "--hc-body-links": this.bodyLinkColor,
        };
      },
      isGenuityDomain() {
        return window.location.host.includes('genuity');
      },
    },
    created() {
      this.forceMount = true;
      this.helpCenterModernDesignEnabled = localStorage.getItem('helpCenterModernDesignEnabled') || true;
      this.helpCenterHyperlinksColor = localStorage.getItem('helpCenterHyperlinksColor') || "";
      this.helpCenterBGColor = localStorage.getItem('helpCenterBGColor') || "";
    },
    mounted() {
      this.fetchCustomDomain();
      this.fetchHelpCenterDesign().then(() => {
        localStorage.setItem('helpCenterModernDesignEnabled', this.helpCenterModernDesignEnabled);
        this.fetchWorkspaces();
      });
      this.fetchHelpCenterColors().then(() => {
        localStorage.setItem( 'helpCenterHyperlinksColor', this.helpCenterHyperlinksColor);
        localStorage.setItem( 'helpCenterBGColor', this.helpCenterBGColor);
      });
    },
    methods: {
      ...mapActions(['fetchHelpCenterDesign', 'fetchHelpCenterColors']),
      ...mapMutations(['setCustomDomain']),
      onWorkspaceChange() {
        if (!this.alreadySent) {
          this.fetchHelpCenterDesign().then(() => {
            localStorage.setItem('helpCenterModernDesignEnabled', this.helpCenterModernDesignEnabled);
            this.fetchWorkspaces();
          });
          this.fetchHelpCenterColors().then(() => {
            localStorage.setItem( 'helpCenterHyperlinksColor', this.helpCenterHyperlinksColor);
            localStorage.setItem( 'helpCenterBGColor', this.helpCenterBGColor);
          });
        }
      },
      routeForWorkspaces(workspaces) {
        if ((this.company && this.company.isolateWorkspaces && workspaces && workspaces.length > 0) ||
              (workspaces && workspaces.length === 1))
        {
          // If we are isolating the workspaces AND the current workspace isn't in the list of workspace options,
          // then we need to save a different one.
          const workspace = workspaces[0];
          setWorkspaceToStorage(workspace);
          this.workspaces = workspaces;
          if (!this.helpCenterModernDesignEnabled) {
            this.$router.push(`/workspaces/${workspace.id}`);
          }
        }
        if (this.helpCenterModernDesignEnabled) {
          const workspaceId = this.$route.params.workspace_id;
          if (workspaceId) {
            const workspace = workspaces.find(w => w.id === parseInt(workspaceId, 10));
            setWorkspaceToStorage(workspace);
          } else {
            const workspace = workspaces[0];
            setWorkspaceToStorage(workspace);
            this.$router.push(`/workspaces/${workspace.id}`);
          }
        }
      },
      routeForForms() {
        const currentWorkspace = getWorkspaceFromStorage();
        if (currentWorkspace && currentWorkspace.id) {
          const selectedWorkspace = this.workspaces.find((w) => w.id === currentWorkspace.id);
          if (!selectedWorkspace) {
            setWorkspaceToStorage(this.workspaces[0]);
          }
        }
      },
      ensureCorrectPath(workspaces) {
        if (this.workspaceSelectionRouteNames.includes(this.$route.name)) {
          this.routeForWorkspaces(workspaces);
        }
      },
      fetchWorkspaces() {
        const params = { ticket_portal: true };
        this.workspaces = null;
        http
          .get(`/workspace_options.json`, { params })
          .then((res) => {
            if (res.data.length > 0) {
              if (this.routesToFilterWorkspaces.includes(this.$route.name) && !this.helpCenterModernDesignEnabled) {
                const data = res.data.filter(workspace => workspace.publicFormsEnabled);
                this.workspacesConfig(data);
              } 
              else {
                this.workspacesConfig(res.data);
              }
            } else {
              this.workspaces = res.data;
            }
          })
          .catch((error) => {
            this.emitError(`Sorry, there was an error loading workspaces. ${error.response.data.message}.`);
          });
      },
      workspacesConfig(data) {
        this.ensureCorrectPath(data);
        this.workspaces = data;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .helpcenter-menu {
    padding-left: 5.5rem;
  }
</style>
