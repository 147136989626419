<template>
  <label
    class="clickable slide-toggle-wrap mb-0"
    :class="{
      'disabled': isPending || disabled,
      'slide-toggle-wrap--with-hover': showHoverState,
      'transformed-toggle': transformed
    }"
    @click.stop.prevent="toggleActive"
  >
    <input
      v-model="isActive"
      type="checkbox"
      class="slide-toggle-input"
    >
    <span
      v-if='slideTextPosition === "left"'
      class="slide-text"
      :class="slideTextClasses"
    >
      <slot />
    </span>
    <span
      class="slide-toggle"
      :class="{ 'slide-toggle--active': isActive }"
      :data-tc-active-task="isActive"
    >
      <span
        class="slide-toggle-thumb"
        data-tc-slide-toggle
      />
    </span>
    <span
      v-if='slideTextPosition === "right"'
      class="slide-text"
      :class="slideTextClasses"
    >
      <slot />
    </span>
  </label>
</template>

<script>
  export default {
    props: {
      initActive: {
        type: Boolean,
        default: false,
      },
      initPending: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      slideTextPosition: {
        type: String,
        default: "right",
      },
      slideTextClasses: {
        type: String,
        default: "",
      },
      showHoverState: {
        type: Boolean,
        default: false,
        required: false,
      },
      transformed: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isActive: this.initActive,
        isPending: this.initPending,
      };
    },
    watch: {
      initPending() {
        this.isPending = this.initPending;
      },
      initActive() {
        this.isActive = this.initActive;
      },
    },
    methods: {
      toggleActive() {
        if (!this.disabled) {
          this.isActive = !this.isActive;
          this.$emit('toggle-sample', this.isActive);
          this.$emit('toggle-out-of-office', this.isActive);
          this.$emit('toggle-email-footer', this.isActive);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.slide-toggle-input {
  display: none;

  &.disabled {
    pointer-events: none;
  
    .slide-toggle {
      pointer-events: none;
      background-color: $themed-light;
    }
    .slide-toggle--active .slide-toggle-thumb {
      background-color: $gray-500;
    }
  }
}

.slide-toggle-wrap--with-hover {
  padding: 0.375rem 0.75rem;

  &:hover {
    background: $themed-light;
  }
}

.slide-toggle {
  background-color: $gray-500;
  border-radius: 0.4375rem;
  display: inline-block;
  height: 0.875rem;
  margin-right: 0.5rem;
  position: relative;
  transition: background-color 0.1s ease-in-out;
  transform: translate3d(0,0,0);
  vertical-align: middle;
  width: 2.25rem;

  @media($max: $medium) {
    width: 2rem;
    height: 0.6875rem;
  }

  .slide-toggle-input:checked ~ &,
  &.slide-toggle--active {
    background-color: lighten($primary, 35%);
  }
}

.slide-toggle-thumb {
  background-color: white;
  border-radius: 50%;
  box-shadow: $shadow-base;
  height: 1.25rem;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(0,-50%,0);
  transition: all 0.1s ease-in-out;
  width: 1.25rem;

  .slide-toggle--active & {
    background-color: $themed-link;
    transform: translate3d(1rem,-50%,0);
  }

  @media($max: $medium) {
    width: 1.0625rem;
    height: 1.0625rem;
  }
}

.slide-text {
  color: $themed-dark;
  vertical-align: middle;
}

.transformed-toggle {
  transform-origin: right top;
  transform: scale(0.875);

  .box--with-switch & {
    transform: scale(0.75);
  }
}
</style>
