import _find from "lodash/find";
import http from 'common/http';
import { mapGetters } from 'vuex';
import helpdeskCustomFormHelper from "./helpdesk_custom_form_helper";
import assetCustomFormHelper from "./asset_custom_form_helper";
import locationCustomFormHelper from "./location_custom_form_helper";
import contractCustomFormHelper from "./contract_custom_form_helper";
import vendorCustomFormHelper from "./vendor_custom_form_helper";
import telecomCustomFormHelper from "./telecom_custom_form_helper";
import companyUserCustomFormHelper from "./company_user_custom_form_helper";
import rootDomain from "./root_domain";

export default {
  mixins: [
    helpdeskCustomFormHelper,
    assetCustomFormHelper,
    locationCustomFormHelper,
    contractCustomFormHelper,
    vendorCustomFormHelper,
    telecomCustomFormHelper,
    companyUserCustomFormHelper,
    rootDomain,
  ],
  data() {
    return {
      companyModuleOptions : [
        { companyModule: 'asset', name: 'managed asset', label: 'Managed Asset', companyModuleType: 'asset'},
        { companyModule: 'helpdesk', name: 'help ticket', label: 'Help Desk', companyModuleType: 'ticket' },
        { companyModule: 'contract', name: 'contract', label: 'Contract', companyModuleType: 'contract' },
        { companyModule: 'vendor', name: 'vendor', label: 'Vendor', companyModuleType: 'vendor' },
        { companyModule: 'telecom', name: 'telecom', label: 'Telecom', companyModuleType: 'telecom' },
        { companyModule: 'company_user', name: 'company_user', label: 'Company User', companyModuleType: 'company user' },
        { companyModule: 'location', name: 'location', label: 'Location', companyModuleType: 'location' },
      ],
      commonPaletteItems: ["text", "static_text", "text_area", "rich_text", "number", "list", "date", "phone", "email", "tag", "radio_button", "checkbox"],
      deletedAttachmetIds: [],
    };
  },
  computed: {
    ...mapGetters('GlobalStore', [
      'mspCustomFormModule',
    ]),
    getModuleData() {
      const value = _find(this.companyModuleOptions, { companyModule: `${this.companyModule}` });
      return value;
    },
    getCompanyModule() {
      const { href } = window.location;
      if (this.isMspRoute()) {
        return this.mspCustomFormModule;
      }
      if (href.includes("/help_tickets")) {
        return 'helpdesk';
      } else if (href.includes("/related_companies")) {
        return this.$route.query.module;
      } else if (href.includes("/managed_assets")) {
        return 'asset';
      } else if (href.includes("/locations")) {
        return 'location';
      } else if (href.includes("users") || href.includes("profile")) {
        return 'company_user';
      }
      return '';
    },
  },
  methods: {
    setValueToDefault() {
      if (this.field && (this.field.type === "toggle" || this.field.type === "closure_toggle")) {
        this.fieldValue = false;
      } else if (this.field && this.field.type === "position") {
        this.positionValue = 'left';
      } else {
        this.fieldValue = '';
      }
    },
    isMspRoute() {
      return document.location.pathname.startsWith("/related_companies");
    },
    deleteEntityValue(field, value) {
      this.object.deletedValues.push({
        customFormFieldId: field.id,
        value,
      });
    },
    saveEntityValue(field, value) {
      let idx = this.object.values.findIndex((i) => i.customFormFieldId === field.id);
      while (idx > -1) {
        this.object.values.splice(idx, 1);
        idx = this.object.values.findIndex((i) => i.customFormFieldId === field.id);
      }
      if (Array.isArray(value)) {
        if (field.files && field.files === value) {
          value.map(attachment =>
            this.object.values.push({
              customFormFieldId: field.id,
              attachmentId: attachment.id,
            })
          );
        } else {
          for (idx = 0; idx < value.length; idx+=1) {
            if (typeof value[idx] === 'string') {
              this.object.values.push({
                customFormFieldId: field.id,
                valueStr: value[idx].trim(),
              });
            } else {
              this.object.values.push({
                customFormFieldId: field.id,
                valueInt: value[idx],
              });
            }
          }
        }
      } else if (typeof value === 'object' && value !== null && value.id ) {
        if (typeof value.id === 'number') {
          this.object.values.push({
            customFormFieldId: field.id,
            valueInt: value.id,
          });
        } else if (typeof value.id === 'string') {
          this.object.values.push({
            customFormFieldId: field.id,
            valueStr: value.name,
          });
        }
      } else if (typeof value === 'string') {
        this.object.values.push({
          customFormFieldId: field.id,
          valueStr: value,
        });
      } else if (field.fieldAttributeType === "priority") {
        this.object.values.push({
          customFormFieldId: field.id,
          valueStr: Object.keys(value)[0],
        });
      } else {
        this.object.values.push({
          customFormFieldId: field.id,
          valueInt: value,
        });
      }
    },
    isUserValue() {
      return this.companyModule === "company_user" && this.userFields.includes(this.field.name) && this.field.targetUser;
    },
    hasSpecialCharacter(input) {
      const specialChars = /^[A-Za-z0-9 ]*$/;
      return !specialChars.test(input);
    },
    deletedAttachmetId(id) {
      this.handleAttachmentRemove(id);
      this.deletedAttachmetIds.push(id);
    },
    removeAttachmetUploads() {
      const params = { attachment_ids: this.deletedAttachmetIds };
      http
        .delete(`/attachment_uploads/bulk_delete_attachments.json`, { params })
        .catch(error => {
          this.emitError(`Sorry, there was an error deleting attachments. ${error.response.data.message}`);
        });
    },
    isPeopleAndCompanyNameFieldForm(formName) {
      const lowerCasedFormName = formName.toLowerCase();

      return this.isPeopleModule && (lowerCasedFormName.includes('partners') || lowerCasedFormName.includes('contractors'));
    },
    workspaceInboundEmail(formName) {
      const workspaceName = this.toHyphenCase(this.$route.query.workspaceName);
      return `${workspaceName}-${this.toHyphenCase(formName)}@${this.currentDomain()}`;
    },
    isStatusField() {
      return this.field && this.field.type === 'status' && this.field.options;
    },
  },
};
