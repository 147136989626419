import _camelCase from "lodash/camelCase";
import customFormHelper from 'mixins/custom_form_helper.js';

export default {
  mixins: [customFormHelper],
  props: {
    field: {
      type: Object,
    },
    value: {
      type: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setTextOrEmailFieldValue() {
      if (this.isUserValue()) {
        this.$emit("input", this.field.targetUser[_camelCase(this.field.name)]);
      } else if (this.field && this.field.customFormValue && this.field.customFormValue.valueStr) {
        this.$emit("input", this.field.customFormValue.valueStr);
      } else if (this.field && this.field.customFormValue && !this.field.customFormValue.valueStr && this.field.defaultValue) {
        this.$emit("input", this.field.defaultValue);
      }
    },
    setTagFieldValue() {
      if (this.field && this.field.customFormValue && this.field.customFormValue.length > 0) {
        this.field.customFormValue.forEach(val => {
          this.valueStr.push(val.valueStr);
        })
      } else if (this.value) {
        this.value.forEach(val => {
          this.valueStr.push(val);
        })
        this.emitValue("input", this.valueStr);
      }
    },
    setCheckboxFieldValue() {
      if (this.field && this.field.customFormValue && this.field.customFormValue.length > 0) {
        this.field.customFormValue.forEach(val => {
          this.valueStr.push(val.valueStr);
        })
      } else if (this.value) {
        this.value.forEach(val => {
          this.valueStr.push(val);
        })
      }
      this.emitValue("input", this.valueStr);
    },
    setSmartListFieldValue() {
      if (this.field && this.field.customFormValue && this.field.customFormValue.length > 0) {
        this.field.customFormValue.forEach(val => {
          this.value.push(val.valueInt);
        });
      }
    },
    setValueStr() {
      if (this.field && this.field.customFormValue && this.field.customFormValue.valueStr) {
        this.valueStr = this.field.customFormValue.valueStr;
        this.emitValue(this.valueStr);
      }
    },
    setPhoneFieldValue() {
      if (this.field && this.field.customFormValue && this.field.customFormValue.valueStr) {
        let phoneValue = this.field.customFormValue.valueStr.split(',');
        this.valueStr = phoneValue[phoneValue.length === 1 ? 0 : 1];

        let phoneObject = {
          countryCode: phoneValue.length > 1 ? phoneValue[0] : '',
          formatted: this.valueStr || '',
          valid: true,
        };
        this.emitValue('', phoneObject);
      }
    },
    setAvatar() {
      if (this.field && this.field.customFormValue && this.field.customFormValue.attachment) {
        this.imgSrc = this.field.customFormValue.attachment.url;
        this.values.push(this.field.customFormValue.attachment);
        this.field.files = this.values;
        this.$emit("input", this.values);
      }
    },
  }
}
