<template>
  <div>
    <input
      ref="input"
      class="form-control"
      placeholder="Enter Email"
      type="email"
      :value="value"
      data-tc-text
      maxlength="200"
      data-tc-task-value
      @input="emitValue($event.target.value)"
    >
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import customFormHelper from 'mixins/custom_form_helper';

export default {
  mixins: [ fieldViewerInput, permissionsHelper, customFormHelper ],
  props: {
    field: {
      type: Object,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      errorMsg: '',
      companyModule: '',
      userFields: ['first_name', 'last_name', 'email'],
    };
  },
  computed: {
    targetUser() {
      return this.field.targetUser;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.companyModule = this.getCompanyModule;
      this.setTextOrEmailFieldValue();
    },
    emitValue: _debounce(function emitValue(value) {
      this.errors = null;
      this.$emit("input", value.trim());
    }, 1000),
    setErrors(msg) {
      this.errorMsg = msg;
    },
    showErrors() {
      let msg = null;
      const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (!this.value && !this.required && this.moduleName !== "automatedTasks") {
        this.$refs.input.setCustomValidity('');
        return false;
      }
      if (!this.value || this.value.length === 0) {
        msg = `Please enter a ${this.field.label}.`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else if (!emailRegex.test(this.value)) {
        msg = `Please enter a valid ${this.field.label}`;
        if (this.$refs.input) {
          this.$refs.input.setCustomValidity(msg);
        }
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
    showEmailError(msg) {
      if (this.field.name === 'email' && this.$refs.input) {
        this.$refs.input.setCustomValidity(this.errors);
        this.errors = msg;
      }
    },
  },
};
</script>
