import { render, staticRenderFns } from "./nice_datepicker.vue?vue&type=template&id=563b5281&scoped=true&"
import script from "./nice_datepicker.vue?vue&type=script&lang=js&"
export * from "./nice_datepicker.vue?vue&type=script&lang=js&"
import style0 from "./nice_datepicker.vue?vue&type=style&index=0&id=563b5281&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563b5281",
  null
  
)

export default component.exports