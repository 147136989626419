import http from 'common/http';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';
import customForms from './mixins/custom_forms_mixin';
import GlobalStore from './global_store';

export default ({
  strict: process.env.NODE_ENV === "development",

  modules: {
    customForms: {
      namespaced: true,
      ...customForms,
    },
    GlobalStore: {
      namespaced: true,
      ...GlobalStore,
    },
  },

  state: {
    moduleName: "HelpTicket",
    moduleFilter: null,
    automatedTasks: null,
    contributorOptions: null,
    searchTerm: '', 
  },

  mutations: {
    setContributorOptions: (state, options) => { state.contributorOptions = options; },
    setAutomatedTasks: (state, tasks) => { state.automatedTasks = tasks; },
    setModuleFilter: (state, moduleFilter) => { state.moduleFilter = moduleFilter; },
    setSearchTerm: (state, searchTerm) => { state.searchTerm = searchTerm; },
  },

  getters: {
    contributorOptions: state => state.contributorOptions,
    automatedTasks: state => state.automatedTasks,
    moduleFilter: state => state.moduleFilter,
    searchTerm: state => state.searchTerm,
  },

  actions: {
    updateDisable({ dispatch }, task) {
      if (!task.disabledAt) {
        return dispatch("disableTask", task);
      }
      return dispatch("enableTask", task);
    },
    enableTask({ commit }, task) {
      return http
        .put(`/automated_task_disables/${task.id}.json`)
        .catch(error => {
          commit('GlobalStore/setErrorMessage', `Sorry, there was an error saving the task. ${error.response.data.message}`);
        });
    },
    disableTask({ commit }, task) {
      return http
        .delete(`/automated_task_disables/${task.id}.json`)
        .catch(error => {
          commit('GlobalStore/setErrorMessage', `Sorry, there was an error saving the task. ${error.response.data.message}`);
        });
    },

    fetchAutomatedTasks({ getters }) {
      const params = { mod: getters.moduleFilter };
      if (getters.searchTerm) {
        params.search = getters.searchTerm;
      }

      return http.get('/automated_tasks.json', { params });
    },

    fetchContributorOptions({ commit }) {
      commit('setContributorOptions', null);
      return http
        .get('/contributor_options.json')
        .then((res) => {
          commit('setContributorOptions', res.data);
        });
    },
  },

  plugins: [
    createPersistedState({
      localStorage: {
        getState: key => Cookies.getJSON(key),
        setState: (key, state) => Cookies.set(key, state, { expires: 3 }),
        removeItem: key => Cookies.remove(key),
      },
      paths: [
        'moduleFilter',
      ],
    }),
  ],
});
