import http from "common/http";
import createPersistedState from 'vuex-persistedstate';
import _get from "lodash/get";
import _find from "lodash/find";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";
import _camelCase from "lodash/camelCase";
import { v4 as uuidv4 } from 'uuid';
import { libraryDocs } from "./mixins/library_documents_mixins";
import customForms  from "./mixins/custom_forms_mixin";
import GlobalStore from "./global_store";
import inDepthTicketReport from './mixins/in_depth_ticket_report';
import ArticlesStore from "./articles_store";
import AutomatedTasksStore from "./automated_tasks_store";

export default new Vuex.Store({
  strict: process.env.NODE_ENV === "development",

  modules: {
    libraryDocs: {
      namespaced: true,
      ...libraryDocs,
    },
    inDepthTicketReport: {
      namespaced: true,
      ...inDepthTicketReport,
    },
    customForms: {
      namespaced: true,
      ...customForms,
    },
    GlobalStore: {
      namespaced: true,
      ...GlobalStore,
    },
    ArticlesStore,
    AutomatedTasksStore,
  },

  state: {
    moduleName: "HelpTicket",
    sortColumn: null,
    sortDirection: null,
    sortType: null,
    contributorOptions: null,
    selectedContributor: null,
    tickets: [],
    isAgent: false,
    loading: false,
    helpCenterTickets: [],
    helpCenterCount: 0,
    currentUserTicketCount: 0,
    inProgressTicketCount: 0,
    closedTicketCount: 0,
    highTicketCount: 0,
    mediumTicketCount: 0,
    lowTicketCount: 0,
    archiveTicketCount: 0,
    statusCount: 0,
    activeCount: 0,
    priorityCount: 0,
    totalTicketCount: 0,
    currentContributorIds: [],
    page: 0,
    perPage: 25,
    pageCount: 0,
    activitiesPage: 0,
    activitiesPerPage: 25,
    activitiesPageCount: 0,
    commentsPage: 0,
    commentsPerPage: 50,
    commentsPageCount: 0,
    faqsPage: 0,
    faqsPerPage: 10,
    faqsPageCount: 0,
    publicArticles: null,
    publicArticlesPage: 0,
    publicArticlesPerPage: 10,
    publicArticlesPageCount: 0,
    publicArticlesCategories: [],
    helpCenterSelectedFaq: null,
    helpCenterSelectedArticle: null,
    helpCenterBGColor: "", 
    helpCenterModernDesignEnabled: false,
    includeWorkspaceEnabled: false,
    helpCenterHyperlinksColor: "",
    loadingHelpCenterDesign: true,
    publicFaqsEnabled: false,
    publicArticlesEnabled: false,
    channelKey: "",
    companyChannelKey: "",
    currentUserId: "",
    requestEmail: "",
    searchTerms: "",
    contractAssetTicketNames: null,
    loadingStatus: false,
    ticketLoadingStatus: false,
    preventListTableLoadingBehavior: false,
    valuesToRemove: [],
    actionSuggestion: null,
    helpTicketPriorityOptions: [
      { name: "Low", value: "low" },
      { name: "Medium", value: "medium" },
      { name: "High", value: "high" },
    ],
    helpTicketAssignmentOptions: [{ name: "Assigned to me", value: "me" }],

    devices: [],
    companyUserOptions: [],
    companyLocations: null,

    dateFilter: null,
    customDateFilter: null,
    dueSoonFilter: null,
    recentlyClosedFilter: null,
    completedTicketFilter: null,
    filterByField: [],
    companyFilter: [],
    workspaceFilter: [],
    previousWorkspaceId: null,
    formFilter: [],
    statusFilter: [],
    sourceFilter: [],
    priorityFilter: [],
    createdByFilter: [],
    assignedToFilter: [],
    selectedColumns: [],
    unselectedColumns: [],
    helpTicketPreferences: [],
    managedAssetFilter: null,

    topUsers: [],
    ticketEmails: [],
    incomingCount: 0,
    currentHelpTicket: null,
    faqs: null,
    publicFaqs: null,
    faq: null,
    tasksTotalCount: 0,
    tasksCompletedCount: 0,
    tasks: [],
    comments: [],
    activities: null,
    timeSpents: [],
    ticketActivities: null,
    activeImportFileName: "",
    helpdeskSettings: [],
    helpdeskEmails: [],
    setting: null,
    loadingTicket: true,
    showPreviousTicketBtn: true,
    showNextTicketBtn: true,
    isDateColumn: false,
    myTickets: false,
    helpdeskEmailSetting: null,
    assignmentFilter: null,
    closedByDateFilter: null,
    filtersData: [],
    filtersSnapShot: [],
    multipleSelectFilters: [
      'setStatusFilter',
      'setPriorityFilter',
      'setCustomFormFilter',
      'setFilterByField',
      'setCreatedByFilter',
      'setAssignedToFilter',
      'setSourceFilter',
      'setCompanyFilter',
      'setWorkspaceFilter',
    ],
    smartListFieldValues: [],
    workspaceOptions: [],
    shouldUserRedirectToTickets: false,
    haveUnsavedAttachments: false,
    unsavedAttachmentIds: [],
    attachmentsSaved: false,
    newChecklistTasks: [],
    ticketNumberColumnWidth: "",
    categories: [],
    quickViewTicketId: null,
    modernDesignEnabled: false,
    currentWorkspace: null,
    currentCompany: null,
    emailTemplateType: null,
    workspaceDeletionInProgress: false,
    minimizedWorkspaceDeletion: false,
    updatingMultipleFilters: false,
    showClosedTickets: false,
    customDomain: {
      name: null,
      activeDomain: false,
      verified: false,
      certificateType: "Custom Certificate",
      primaryKey: null,
      primaryCertificate: null,
      intermediaryCertificate: null,
    },
  },

  mutations: {
    setCategories(state, categories) {
      state.categories = categories;
    },
    setHelpdeskEmails(state, emails) {
      state.helpdeskEmails = emails;
    },
    setHelpdeskSettings(state, settings) {
      state.helpdeskSettings = settings;
    },
    setFieldInTicket(state, field) {
      if (!field) { return; }
      const myForm = state.currentHelpTicket.customForm;
      const idx = myForm.formFields.findIndex((f) => f.id === field.id);
      if (idx > -1) {
        myForm.formFields[idx] = field;
      }
      state.currentHelpTicket = _cloneDeep(state.currentHelpTicket);
    },
    setActionSuggestion(state, name) {
      state.actionSuggestion = name;
    },
    updateTicket(state, ticket) {
      const idx = _findIndex(state.tickets, (t) => t.id === ticket.id);
      if (idx > -1) {
        state.tickets.splice(idx, 1, ticket);
      }
    },
    setHelpTicketAssignmentOptions(state, options) {
      state.helpTicketAssignmentOptions = options;
    },
    setPreviousWorkspaceId(state, id) {
      state.previousWorkspaceId = id;
    },
    setTicketEmails(state, ticketEmails) {
      state.ticketEmails = ticketEmails;
    },
    setIncomingCount(state, incomingCount) {
      state.incomingCount = incomingCount;
    },
    setCurrentHelpTicket(state, currentHelpTicket) {
      state.currentHelpTicket = currentHelpTicket;
    },
    setTopUsers(state, users) {
      state.topUsers = users;
    },
    setIsAgent(state, isAgent) {
      state.isAgent = isAgent;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setChannelKey(state, key) {
      state.channelKey = key;
    },
    setPreviousBtn(state, showPreviousTicketBtn) {
      state.showPreviousTicketBtn = showPreviousTicketBtn;
    },
    setNextBtn(state, showNextTicketBtn) {
      state.showNextTicketBtn = showNextTicketBtn;
    },
    setLoadingTicket(state, loadingTicket) {
      state.loadingTicket = loadingTicket;
    },
    setCompanyChannelKey(state, key) {
      state.companyChannelKey = key;
    },
    setCurrentUserId(state, key) {
      state.currentUserId = key;
    },
    setPage(state, page) {
      state.page = page;
    },
    setPageCount(state, pageCount) {
      state.pageCount = pageCount;
    },
    setTotalRecord(state, total) {
      state.totalTicketCount = total;
    },
    setCurrentContributorIds: (state, currentContributorIds) => {
      state.currentContributorIds = currentContributorIds;
    },
    setFaqsPage(state, faqsPage) {
      state.faqsPage = faqsPage;
    },
    setFaqsPageCount(state, faqsPageCount) {
      state.faqsPageCount = faqsPageCount;
    },
    setPerPage(state, perPage) {
      state.perPage = perPage;
    },
    setContractAssetTicketNames(state, names) {
      state.contractAssetTicketNames = names;
    },
    setContributorOptions(state, contributorOptions) {
      state.contributorOptions = contributorOptions;
    },
    setSelectedContributor(state, value) {
      state.selectedContributor = value;
    },
    setSearchTerms(state, searchTerms) {
      state.searchTerms = searchTerms;
    },
    setRequestEmail(state, email) {
      state.requestEmail = email;
    },
    setFiltersSnapShot: (state, filters) => {
      state.filtersSnapShot = filters;
    },
    setTicketNumberColumnWidth(state, ticketNumberColumnWidth) {
      state.ticketNumberColumnWidth = ticketNumberColumnWidth;
    },
    setStatusFilter: (state, status) => {
      if (status.removePrefilter) {
        state.statusFilter = [];
      }
      const prevStatus = state.statusFilter.find(item => item.id === status.id);
      if (!status) {
        state.statusFilter = [];
      } else if (status.id && !prevStatus) {
        state.statusFilter.push(status);
      } else if (!status.location || (status.location && status.location !== "dashboard")) {
        const deleteFilter = prevStatus;
        const index = state.statusFilter.indexOf(deleteFilter);
        state.statusFilter.splice(index, 1);
      }
      if (state.statusFilter.length > 0 && state.recentlyClosedFilter) {
        state.recentlyClosedFilter = null;
      }
    },
    setSourceFilter: (state, source) => {
      if (!source) {
        state.sourceFilter = [];
      } else {
        if (source.removePrefilter) state.sourceFilter = [];
        const prevSourceIndex = state.sourceFilter.findIndex(item => item.id === source.id);
        if (prevSourceIndex !== -1) {
          state.sourceFilter.splice(prevSourceIndex, 1);
        } else if (source.name) {
          state.sourceFilter.push(source);
        }
      }
    },
    setCustomNameSetting: (state, setting) => {
      state.setting = setting;
    },
    setCustomDateFilter: (state, date) => {
      state.dateFilter = null;
      state.customDateFilter = date || null;
    },
    setDateFilter: (state, date) => {
      const prevDateFilter = state.dateFilter;
      state.customDateFilter = null;
      if (!date || (prevDateFilter && prevDateFilter.name === date.name)) {
        state.dateFilter = null;
      } else {
        state.dateFilter = date;
      }
    },
    setDueSoonFilter: (state, dueAt) => {
      state.dueSoonFilter = state.dueSoonFilter ? null : dueAt;
    },
    setRecentlyClosedFilter: (state, filter) => {
      if (state.statusFilter && state.statusFilter.length > 0) {
        state.statusFilter = [];
      }
      state.recentlyClosedFilter = state.recentlyClosedFilter ? null : filter;

    },
    setClosedByDateFilter: (state, filter) => {
      state.closedByDateFilter = filter;
    },
    setCompletedTicketFilter: (state, completedFilter) => {
      state.completedTicketFilter = state.completedTicketFilter ? null : completedFilter;
    },
    setFilterByField: (state, filterByField) => {
      if (Array.isArray(filterByField) && filterByField.length === 0) {
        state.filterByField = [];
        return;
      }

      const prevFilterByField = state.filterByField.find(item => item.id === filterByField.id);
      if (filterByField.id && !prevFilterByField) {
        state.filterByField.push(filterByField);
      } else {
        const deleteFilter = prevFilterByField;
        state.filterByField.splice(state.filterByField.indexOf(deleteFilter), 1);
      }
    },
    setCompanyFilter: (state, company) => {
      const companyObject =  { ...company };
      if (companyObject && companyObject.removePrefilter) {
        state.companyFilter = [];
      }
      let workspace = null;
      let deleteCompany = null;
      let selectedCompanyIsPresent = null;
      if (companyObject) {
        companyObject.label = 'Company';
        selectedCompanyIsPresent = state.companyFilter.find(item => item.id === companyObject.id);
        deleteCompany = selectedCompanyIsPresent;
      }
      if (state.companyFilter.length > 0 && companyObject) {
        if (!selectedCompanyIsPresent) {
          workspace = state.workspaceFilter;
          state.workspaceFilter = [];
        }
      } else if ((!companyObject || state.companyFilter.length === 0) && state.workspaceFilter) {
        workspace = state.workspaceFilter;
        state.workspaceFilter = [];
      }

      if (deleteCompany) {
        if (state.companyFilter.length === 1 && state.workspaceOptions.length === 1 && state.workspaceFilter) {
          workspace = state.workspaceFilter;
          state.workspaceFilter = [];
        }
        state.companyFilter.splice(state.companyFilter.indexOf(deleteCompany), 1);
      } else if (companyObject && companyObject.id) {
        state.priorityFilter = [];
        state.formFilter = [];
        state.assignedToFilter = [];
        state.createdByFilter = [];
        state.sourceFilter = [];
        state.companyFilter.push(companyObject);
      }
      // Commenting this for fixing the filters reset issue
      // state.formFilter = [];
      // state.priorityFilter = [];
      // state.createdByFilter = [];
      // state.assignedToFilter = [];
      if (state.filtersData.length > 0 && workspace && workspace.length > 0) {
        const deletePrevFilter = state.filtersData.find(data => data.item.id === workspace[0].id);
        state.filtersData.splice(state.filtersData.indexOf(deletePrevFilter), 1);
        localStorage.setItem(`user ${state.currentUserId}-workspace ${getWorkspaceFromStorage().id}`, JSON.stringify(state.filtersData));
      }
      window.sessionStorage.setItem("companyFilter", JSON.stringify(state.companyFilter));
    },
    setWorkspaceFilter: (state, workspace) => {
      if (workspace && workspace.removePrefilter) state.workspaceFilter = [];
      if (!workspace || !Object.keys(workspace).length) {
        state.workspaceFilter = [];
        window.sessionStorage.setItem("workspaceFilter", []);
        return;
      }
      const deleteWorkspace = state.workspaceFilter.find(item => item.id === workspace.id);
      const isCompanyWorkspace = !(Array.isArray(workspace)) ? state.companyFilter.find(company => company.name === workspace.company.name) : false;

      if (deleteWorkspace) {
        state.workspaceFilter.splice(state.workspaceFilter.indexOf(deleteWorkspace), 1);
      } else if (workspace && workspace.id && (state.companyFilter.length === 0 || (state.companyFilter.length > 0 && isCompanyWorkspace))) {
        state.workspaceFilter.push(workspace);
      }
      if (workspace && state.previousWorkspaceId !== workspace.id) {
        state.formFilter = [];
        state.priorityFilter = [];
        state.createdByFilter = [];
        state.assignedToFilter = [];
      }
      window.sessionStorage.setItem("workspaceFilter", JSON.stringify(workspace));
    },
    setCustomFormFilter: (state, form) => {
      const prevFormFilter = state.formFilter.find(item => item.id === form.id);
      if (!form || form?.length === 0) {
        state.formFilter = [];
      } else if (form.id && !prevFormFilter) {
        state.formFilter.push(form);
      } else {
        const deleteFilter = prevFormFilter;
        state.formFilter.splice(state.formFilter.indexOf(deleteFilter), 1);
      }
    },
    setAssignedToFilter: (state, assignedTo) => {
      const prevAssignedFilter = state.assignedToFilter.find(item => item.id === assignedTo.id);
      if (!Object.keys(assignedTo).length) {
        state.assignedToFilter = [];
      } else if (assignedTo.id && !prevAssignedFilter) {
        state.assignedToFilter.push(assignedTo);
      } else {
        const deleteFilter = prevAssignedFilter;
        state.assignedToFilter.splice(state.assignedToFilter.indexOf(deleteFilter), 1);
      }
    },
    setPriorityFilter: (state, priority) => {
      const prevPriorityFilter = state.priorityFilter.find(item => item.id === priority.id);
      if (!priority) {
        state.priorityFilter = [];
      } else if (!prevPriorityFilter) {
        state.priorityFilter.push(priority);
      } else if (!priority.location || (priority.location && priority.location !== "dashboard")) {
        const deleteFilter = prevPriorityFilter;
        state.priorityFilter.splice(state.priorityFilter.indexOf(deleteFilter), 1);
      }
    },
    setMyTickets: (state, myTicket) => {
      state.myTickets = myTicket;
    },
    setCreatedByFilter: (state, createdBy) => {
      const prevCreatedByFilter = state.createdByFilter.find(item => item.id === createdBy.id);
      if (!createdBy) {
        state.createdByFilter = [];
      } else if (createdBy.id && !prevCreatedByFilter) {
        state.createdByFilter.push(createdBy);
      } else {
        const deleteFilter = prevCreatedByFilter;
        state.createdByFilter.splice(state.createdByFilter.indexOf(deleteFilter), 1);
      }
    },
    setManagedAssetFilter: (state, managedAsset) => {
      state.managedAssetFilter = managedAsset;
    },
    setLoadingStatus(state, status) {
      state.loadingStatus = status;
    },
    setTicketLoadingStatus(state, status) {
      state.ticketLoadingStatus = status;
    },
    setPreventListTableLoadingBehavior(state, status) {
      state.preventListTableLoadingBehavior = status;
    },
    updateTicketsWithIds: (state, ids) => {
      const mapping = {};
      state.tickets.forEach((t) => {
        mapping[t.id] = t;
      });
      state.tickets = ids.map((i) => (mapping[i] ? mapping[i] : { id: i }));
    },
    setHelpCenterTickets(state, helpCenterTickets) {
      state.helpCenterTickets.splice(0);
      if (!helpCenterTickets) {
        return;
      }
    },

    setTickets(state, tickets) {
      const ticketsCopy = tickets;
      const len = ticketsCopy.length;
      state.tickets.splice(0);
      const currentTicketIds = [];
      for (let idx = 0; idx < len; idx += 1) {
        const ticketObject = { ...ticketsCopy[idx] };
        ticketObject.showDetails = false;
        if (state.helpCenterTickets.indexOf(ticketObject.id) > -1) {
          ticketObject.showDetails = true;
        }
        state.tickets.push(ticketObject);
        currentTicketIds.push(ticketObject.id);
      }
      const ticketsToClose = [];
      state.helpCenterTickets.forEach((id) => {
        if (currentTicketIds.indexOf(id) === -1) {
          ticketsToClose.push(id);
        }
      });
      ticketsToClose.forEach((id) => {
        state.helpCenterTickets.splice(state.helpCenterTickets.indexOf(id), 1);
      });
    },

    setSummaryCounts(state, data) {
      state.totalTicketCount = data.totalTicketCount;

      state.closedTicketCount = data.closedTicketCount;
      state.inProgressTicketCount = data.inProgressTicketCount;
      state.helpCenterCount = data.helpCenterCount;
      state.archiveTicketCount = data.archiveTicketCount;

      state.highTicketCount = data.highTicketCount;
      state.mediumTicketCount = data.mediumTicketCount;
      state.lowTicketCount = data.lowTicketCount;

      state.statusCount =
        state.archiveTicketCount +
        state.helpCenterCount +
        state.inProgressTicketCount +
        state.closedTicketCount;
      state.priorityCount =
        state.highTicketCount + state.mediumTicketCount + state.lowTicketCount;
      state.activeCount = data.activeTicketCount;
      state.currentUserTicketCount = data.currentUserTicketCount;
    },
    updateTicketInTickets: (state, ticket) => {
      for (let idx = 0; idx < state.tickets.length; idx += 1) {
        if (state.tickets[idx].id === ticket.id) {
          Vue.set(state.tickets, idx, ticket);
        }
      }
    },
    setDefaultSelectedColumns(state, selected) {
      state.selectedColumns = selected;
    },
    setDefaultUnSelectedColumns(state, unselected) {
      state.unselectedColumns = unselected;
    },
    removeUnselectedActive(state, index) {
      if (state.selectedColumns[index]) {
        state.selectedColumns[index].active = false;
      }
    },
    removeSelectedActive(state, index) {
      if (state.selectedColumns[index]) {
        state.selectedColumns[index].active = false;
      }
    },
    setUnselectedColumns(state) {
      state.unselectedColumns = [...state.selectedColumns.filter(d => d.active), ...state.unselectedColumns];
      state.selectedColumns = state.selectedColumns.filter(d => !d.active);
    },
    setSelectedColumn(state, column) {
      state.selectedColumns = column;
    },
    setUnselectedColumn(state, column) {
      state.unselectedColumns = column;
    },
    setSelectedColumns(state) {
      state.selectedColumns = [...state.selectedColumns, ...state.unselectedColumns.filter(d => d.active)];
      state.unselectedColumns = state.unselectedColumns.filter(d => !d.active);
    },
    setHelpTicketPreferences: (state, preferences) => {
      state.helpTicketPreferences = preferences;
    },
    clearActive(state) {
      state.selectedColumns = state.selectedColumns.map(d => ({ ...d, active: false }));
      state.unselectedColumns = state.unselectedColumns.map(d => ({ ...d, active: false }));
    },
    removeTicketInTickets: (state, ticket) => {
      for (let idx = 0; idx < state.tickets.length; idx += 1) {
        if (state.tickets[idx].id === ticket.id) {
          break;
        }
        state.tickets.splice(idx, 1);
      }
    },
    setCompanyUserOptions(state, options) {
      state.companyUserOptions = options;
    },
    setDevices(state, devices) {
      state.devices = devices;
    },
    setCompanyLocations: (state, locations) => {
      state.companyLocations = locations;
    },

    setFaqs(state, faqs) {
      state.faqs = faqs;
    },
    setFaq(state, faq) {
      state.faq = faq;
    },
    setTasksTotalCount(state, count) {
      state.tasksTotalCount = count;
    },
    setTasksCompletedCount(state, count) {
      state.tasksCompletedCount = count;
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
    },
    setComments(state, comments) {
      state.comments = comments;
    },
    setTimeSpents(state, timeSpents) {
      state.timeSpents = timeSpents;
    },
    clearTicketFilter(state, filter) {
      state[filter.filterName] = null;
    },
    setDateColumn(state, dateColumn) {
      state.isDateColumn = dateColumn;
    },
    setSortColumn(state, sortColumn) {
      state.sortColumn = sortColumn;
    },
    setSortDirection(state, sortDirection) {
      state.sortDirection = sortDirection;
    },
    setSortType(state, sortType) {
      state.sortType = sortType;
    },
    setActivities(state, activities) {
      state.activities = activities;
    },
    setActivitiesPage(state, page) {
      state.activitiesPage = page;
    },
    setActivitiesPerPage(state, perPage) {
      state.activitiesPerPage = perPage;
    },
    setActivitiesPageCount(state, pageCount) {
      state.activitiesPageCount = pageCount;
    },

    addComment: (state, comment) => {
      let idx = _findIndex(state.comments, (c) => c.id === comment.id);
      if (idx > -1) {
        state.comments.splice(idx, 1, comment);
      } else {
        idx = _findIndex(
          state.comments,
          (c) => moment(comment.createdAt) > moment(c.createdAt)
        );
        state.comments.splice(idx, 0, comment);
      }
      state.comments = state.comments.splice(0);
    },

    setCommentsPage(state, page) {
      (state.commentsPage = page);
    },
    setCommentsPerPage(state, perPage) {
      (state.commentsPerPage = perPage);
    },
    setCommentsPageCount(state, pageCount) {
      (state.commentsPageCount = pageCount);
    },
    removeComment: (state, comment) => {
      const idx = _findIndex(state.comments, (c) => c.id === comment.id);
      if (idx > -1) {
        state.comments.splice(idx, 1);
      }
    },

    addTicket: (state, ticket) => {
      const idx = _findIndex(state.tickets, (c) => c.id === ticket.id);
      if (idx > -1) {
        state.tickets.splice(idx, 1, ticket);
      } else {
        state.tickets.splice(state.tickets.length, 0, ticket);
      }
      state.tickets = state.tickets.splice(0);
    },

    removeTicket: (state, ticket) => {
      const idx = _findIndex(state.tickets, (c) => c.id === ticket.id);
      if (idx > -1) {
        state.tickets.splice(idx, 1);
      }
    },

    removeValueToRemove: (state, valueToRemove) => {
      const value = _find(state.valuesToRemove, valueToRemove);
      const idx = state.valuesToRemove.indexOf(value);
      state.valuesToRemove.splice(idx, 1);
    },

    addUniversalLinkToTicket: (state, linkableLink) => {
      if (!state.currentHelpTicket) {
        return;
      }
      const sourceType = _get(linkableLink, "source.linkable_type");
      const sourceId = _get(linkableLink, "source.linkable_id");
      if (
        sourceType === "HelpTicket" &&
        sourceId === state.currentHelpTicket.id
      ) {
        const idx = _findIndex(
          state.currentHelpTicket.universalLinks,
          (l) =>
            l.target.linkableId === linkableLink.target.linkable_id &&
            l.target.linkableType === linkableLink.target.linkable_type
        );
        if (idx === -1) {
          const target = {};
          Object.keys(linkableLink.target).forEach((k) => {
            (target[_camelCase(k)] = linkableLink.target[k]);
          });
          state.currentHelpTicket.universalLinks.push({
            id: linkableLink.id,
            target,
          });
          state.currentHelpTicket = { ...state.currentHelpTicket };
        }
      }
    },

    removeUniversalLinkToTicket: (state, linkableLink) => {
      if (!state.currentHelpTicket) {
        return;
      }
      const sourceType = _get(linkableLink, "source.linkable_type");
      const sourceId = _get(linkableLink, "source.linkable_id");
      if (
        sourceType === "HelpTicket" &&
        sourceId === state.currentHelpTicket.id
      ) {
        const idx = _findIndex(
          state.currentHelpTicket.universalLinks,
          (l) =>
            l.target.linkableId === linkableLink.target.linkable_id &&
            l.target.linkableType === linkableLink.target.linkable_type
        );
        if (idx > -1) {
          state.currentHelpTicket.universalLinks.splice(idx, 1);
          state.currentHelpTicket = { ...state.currentHelpTicket };
        }
      }
    },
    setActiveImportFileName(state, value) {
      state.activeImportFileName = value;
    },
    setHelpdeskEmailSetting(state, setting) {
      state.helpdeskEmailSetting = setting;
    },
    setAssignmentFilter(state, assignment) {
      if (state.assignmentFilter && state.assignmentFilter.name === assignment.name) {
        state.assignmentFilter = null;
      } else {
        state.assignmentFilter = assignment;
      }
    },
    setFiltersData: (state, filtersData) => {
      if (filtersData.clearAll) {
        state.filtersData = [];
      } else if (filtersData.resetData) {
        state.filtersData = [];
        localStorage.setItem(`user ${state.currentUserId}-workspace ${getWorkspaceFromStorage().id}`, JSON.stringify(state.filtersData));
      } else {
        if (filtersData.item === null) {
          const deletePrevFilter = state.filtersData.find(data => data.filter === filtersData.filter);
          state.filtersData.splice(state.filtersData.indexOf(deletePrevFilter), 1);
          if (deletePrevFilter?.filter === 'setWorkspaceFilter') {
            state.workspaceFilter = null;
          }
        } else {
          let prevFiltersData = null;
          if (state.multipleSelectFilters.includes(filtersData.filter)) {
            prevFiltersData = state.filtersData.find(filter => filter.filter === filtersData.filter && filtersData.item.id && filter.item.id === filtersData.item.id);
          } else {
            prevFiltersData = state.filtersData.find(filter => filter.filter === filtersData.filter);
          }
          if (!prevFiltersData) {
            state.filtersData.push(filtersData);
          } else if (prevFiltersData) {
            const deleteFilter = prevFiltersData;
            if (filtersData.item.name !== prevFiltersData.item.name) {
              state.filtersData.push(filtersData);
            }
            state.filtersData.splice(state.filtersData.indexOf(deleteFilter), 1);
          }
        }
        localStorage.setItem(`user ${state.currentUserId}-workspace ${getWorkspaceFromStorage().id}`, JSON.stringify(state.filtersData));
      }
    },
    setSmartListFieldValues: (state, value) => {
      state.smartListFieldValues[value.field.name] = value.value;
    },
    setWorkspaceOptions: (state, value) => {
      state.workspaceOptions = value;
    },
    setShouldUserRedirectToTickets: (state, value) => {
      state.shouldUserRedirectToTickets = value;
    },
    setUpdatingMultipleFilters: (state, value) => {
      state.updatingMultipleFilters = value;
    },
    setShowClosedTickets: (state, value) => {
      state.showClosedTickets = value;
    },
    setHaveUnsavedAttachments(state, haveUnsavedAttachments) {
      state.haveUnsavedAttachments = haveUnsavedAttachments;
    },
    setUnsavedAttachmentIds(state, unsavedAttachmentIds) {
      state.unsavedAttachmentIds = unsavedAttachmentIds;
    },
    setAttachmentsSaved(state, attachmentsSaved) {
      state.attachmentsSaved = attachmentsSaved;
    },
    setNewChecklistTasks(state, newChecklistTasks) {
      state.newChecklistTasks = newChecklistTasks;
    },
    setQuickViewTicketId(state, quickViewTicketId) {
      state.quickViewTicketId = quickViewTicketId;
    },
    setPublicArticles(state, articles) { 
      state.publicArticles = articles;
    },
    setPublicArticlesPage(state, articlesPage) {
      state.publicArticlesPage = articlesPage;
    },
    setPublicArticlesPageCount:(state, articlesPageCount) => {
      state.publicArticlesPageCount = articlesPageCount;
    },
    setHelpCenterBGColor(state, color) {
      state.helpCenterBGColor = color;
    },
    setHelpCenterModernDesignEnabled(state, enabled) {
      state.helpCenterModernDesignEnabled = enabled;
    },
    setHelpCenterHyperlinksColor(state, color) {
      state.helpCenterHyperlinksColor = color;
    },
    setLoadingHelpCenterDesign(state, value) {
      state.loadingHelpCenterDesign = value;
    },
    setPublicFaqsEnabled(state, value) {
      state.publicFaqsEnabled = value;
    },
    setPublicArticlesCategories(state, categories) {
      state.publicArticlesCategories = categories;
    },
    setPublicFaqs(state, faqs) {
      state.publicFaqs = faqs;
    },
    setHelpCenterSelectedFaq(state, faq) {
      state.helpCenterSelectedFaq = { ...faq };
    },
    setHelpCenterSelectedArticle(state, article) {
      state.helpCenterSelectedArticle = { ...article };
    },
    setPublicArticlesEnabled(state, value) {
      state.publicArticlesEnabled = value;
    },
    setHideWorkspaceSettingEnabled(state, value) {
      state.includeWorkspaceEnabled = value;
    },
    setCurrentWorkspace(state, currentWorkspace) {
      state.currentWorkspace = currentWorkspace;
    },
    setCurrentCompany(state, currentCompany) {
      state.currentCompany = currentCompany;
    },
    setEmailTemplateType(state, emailTemplateType) {
      state.emailTemplateType = emailTemplateType;
    },
    setWorkspaceDeletionInProgress(state, workspaceDeletion) {
      state.workspaceDeletionInProgress = workspaceDeletion;
    },
    setMinimizedWorkspaceDeletion(state, minimized) {
      state.minimizedWorkspaceDeletion = minimized;
    },
    setCustomDomain(state, domain) {
      state.customDomain = domain;
    },
  },

  getters: {
    categories: (state) => state.categories,
    quickViewTicketId: (state) => state.quickViewTicketId,
    currentWorkspace: (state) => state.currentWorkspace,
    currentCompany: (state) => state.currentCompany,
    helpdeskEmails: (state) => state.helpdeskEmails,
    helpdeskSettings: (state) => state.helpdeskSettings,
    helpdeskEmailSetting: (state) => state.helpdeskEmailSetting,
    valuesToRemove: (state) => state.valuesToRemove,
    filtersSnapShot: (state) => state.filtersSnapShot,
    filterParams(state) {
      const params = { page: state.page + 1, per_page: state.perPage };
      if (state.sortColumn) {
        let direction = state.sortDirection;
        if (!direction) {
          direction = "asc";
        }
        if (state.isDateColumn) {
          params.sort = `date_column ${state.sortColumn} ${direction}`;
        } else {
          params.sort = `${state.sortColumn} ${direction}`;
        }
        if (state.sortType) {
          params.sort_type = state.sortType; 
        }
      }
      if (state.statusFilter) {
        params.statuses = state.statusFilter.map(item => item.id);
      } else {
        params.statuses = "";
      }
      if (state.sourceFilter) {
        params.source = state.sourceFilter.map(src => src.id);
      }
      if (state.customDateFilter) {
        params.date_filter = state.customDateFilter.value;
        params.custom_date_filter_type = state.customDateFilter.filterType;
      }
      if (state.dateFilter) {
        params.date_filter = state.dateFilter.value;
        params.filter_type = state.dateFilter.filterType;
      }
      if (state.dueSoonFilter) {
        params.due_soon_filter = state.dueSoonFilter.value;
      }
      if (state.recentlyClosedFilter) {
        params.recently_closed_filter = state.recentlyClosedFilter.value;
      }
      if (state.completedTicketFilter) {
        params.completed_ticket_filter = state.completedTicketFilter.filterType;
      }
      if (state.filterByField) {
        params.filter_by_field = state.filterByField.map((item) => ({ id: item.id, name: item.name }));
      }
      if (state.companyFilter) {
        if (state.companyFilter && state.companyFilter.length > 1) {
          params.company_ids = state.companyFilter.map(item => item.id);
        } else {
          params.company_id = state.companyFilter.length > 0 ? state.companyFilter[0].id: null;
        }
      }
      if (state.workspaceFilter) {
        if (state.workspaceFilter && state.workspaceFilter.length > 1) {
          params.workspace_id = state.workspaceFilter.map(item => item.id);
        } else {
          params.workspace_id = state.workspaceFilter.length > 0 ? state.workspaceFilter[0].id: null;
        }
      }
      if (state.formFilter) {
        params.custom_form_ids = state.formFilter.map(item => item.id);
      }
      if (state.priorityFilter) {
        params.priorities = state.priorityFilter.filter(item => item.id !== 'all').map(item => item.id);
      }
      if (state.closedByDateFilter) {
        params.closed_by_dates = [ state.closedByDateFilter.filterType.startDate, state.closedByDateFilter.filterType.endDate, 'closed_by_date' ];
      }
      params.created_by_ids = state.createdByFilter.filter(item => item.id !== item.name).map(item => item.id);
      params.created_by_emails = state.createdByFilter.filter(item => item.id === item.name).map(item => item.id);
      params.assigned_to_ids = state.assignedToFilter.filter(item => item.id).map(item => item.id);
      params.assigned_to_names = state.assignedToFilter.filter(item => !item.id).map(item => item.name);
      if (state.searchTerms) {
        params.search_terms = state.searchTerms;
      }
      if (state.managedAssetFilter) {
        params.managed_asset_id = state.managedAssetFilter.id;
      }
      if (state.myTickets) {
        params.my_tickets = state.myTickets;
      }
      if (state.assignmentFilter) {
        params.assignment = state.assignmentFilter.name;
      }
      const storage = window.sessionStorage;
      params.window_guid = storage.getItem("windowGuid");
      return params;
    },

    prioritySuggestion: (state) => state.actionSuggestion === "priority",
    devices: (state) => state.devices,
    setting: (state) => state.setting,
    companyUserOptions: (state) => state.companyUserOptions,
    contributorOptions: (state) => state.contributorOptions,
    selectedContributor: (state) => state.selectedContributor,
    companyLocations: (state) => state.companyLocations,
    loadingStatus: state => state.loadingStatus,
    ticketLoadingStatus: state => state.ticketLoadingStatus,
    preventListTableLoadingBehavior: state => state.preventListTableLoadingBehavior,
    perPage: (state) => state.perPage,
    isAgent: (state) => state.isAgent,
    page: (state) => state.page,
    faqsPage: (state) => state.faqsPage,
    topUsers: (state) => state.topUsers,
    pageCount: (state) => state.pageCount,
    faqsPageCount: (state) => state.faqsPageCount,
    tickets: (state) => state.tickets,
    helpCenterCount: (state) => state.helpCenterCount,
    currentUserTicketCount: (state) => state.currentUserTicketCount,
    archiveTicketCount: (state) => state.archiveTicketCount,
    inProgressTicketCount: (state) => state.inProgressTicketCount,
    closedTicketCount: (state) => state.closedTicketCount,
    highTicketCount: (state) => state.highTicketCount,
    mediumTicketCount: (state) => state.mediumTicketCount,
    lowTicketCount: (state) => state.lowTicketCount,
    totalTicketCount: (state) => state.totalTicketCount,
    workspaceOptions: (state) => state.workspaceOptions,
    currentContributorIds: (state) => state.currentContributorIds,
    helpCenterTickets: (state) => state.helpCenterTickets,
    loading: (state) => state.loading,
    searchTerms: (state) => state.searchTerms,
    priorityCount: (state) => state.priorityCount,
    statusCount: (state) => state.statusCount,
    activeCount: (state) => state.activeCount,
    channelKey: (state) => state.channelKey,
    companyChannelKey: (state) => state.companyChannelKey,
    currentUserId: (state) => state.currentUserId,
    requestEmail: (state) => state.requestEmail,
    helpTicketPriorityOptions: (state) => state.helpTicketPriorityOptions,
    helpTicketAssignmentOptions: (state) => state.helpTicketAssignmentOptions,
    statusFilter: (state) => state.statusFilter,
    sourceFilter: (state) => state.sourceFilter,
    dateFilter: (state) => state.dateFilter,
    customDateFilter: (state) => state.customDateFilter,
    filterByField: (state) => state.filterByField,
    dueSoonFilter: (state) => state.dueSoonFilter,
    recentlyClosedFilter: (state) => state.recentlyClosedFilter,
    completedTicketFilter: (state) => state.completedTicketFilter,
    companyFilter: (state) => state.companyFilter,
    workspaceFilter: (state) => state.workspaceFilter,
    formFilter: (state) => state.formFilter,
    createdByFilter: (state) => state.createdByFilter,
    myTickets: (state) => state.myTickets,
    priorityFilter: (state) => state.priorityFilter,
    assignedToFilter: (state) => state.assignedToFilter,
    managedAssetFilter: (state) => state.managedAssetFilter,
    ticketEmails: (state) => state.ticketEmails,
    incomingCount: (state) => state.incomingCount,
    currentHelpTicket: (state) => state.currentHelpTicket,
    faqs: (state) => state.faqs,
    faq: (state) => state.faq,
    contractAssetTicketNames: (state) => state.contractAssetTicketNames,
    tasksTotalCount: (state) => state.tasksTotalCount,
    tasksCompletedCount: (state) => state.tasksCompletedCount,
    tasks: (state) => state.tasks,
    comments: (state) => state.comments,
    commentsPage: (state) => state.commentsPage,
    commentsPerPage: (state) => state.commentsPerPage,
    commentsPageCount: (state) => state.commentsPageCount,
    activities: (state) => state.activities,
    activitiesPage: (state) => state.activitiesPage,
    activitiesPerPage: (state) => state.activitiesPerPage,
    activitiesPageCount: (state) => state.activitiesPageCount,
    timeSpents: (state) => state.timeSpents,
    sortColumn: (state) => state.sortColumn,
    sortDirection: (state) => state.sortDirection,
    sortType: (state) => state.sortType,
    ticketActivities: (state) => state.ticketActivities,
    activeImportFileName: (state) => state.activeImportFileName,
    commonQuickFilters: (state) => state.commonQuickFilters,
    selectedColumns: (state)  => state.selectedColumns,
    unselectedColumns: (state)  => state.unselectedColumns,
    helpTicketPreferences: (state) => state.helpTicketPreferences,
    loadingTicket: (state) => state.loadingTicket,
    showPreviousTicketBtn: (state) => state.showPreviousTicketBtn,
    showNextTicketBtn: (state) => state.showNextTicketBtn,
    isDateColumn: (state) => state.isDateColumn,
    previousWorkspaceId: (state) => state.previousWorkspaceId,
    assignmentFilter: (state) => state.assignmentFilter,
    closedByDateFilter: (state) => state.closedByDateFilter,
    filtersData: state => state.filtersData,
    smartListFieldValues: state => state.smartListFieldValues,
    shouldUserRedirectToTickets: state => state.shouldUserRedirectToTickets,
    updatingMultipleFilters: state => state.updatingMultipleFilters,
    showClosedTickets: state => state.showClosedTickets,
    haveUnsavedAttachments: state => state.haveUnsavedAttachments,
    unsavedAttachmentIds: state => state.unsavedAttachmentIds,
    attachmentsSaved: state => state.attachmentsSaved,
    newChecklistTasks: state => state.newChecklistTasks,
    ticketNumberColumnWidth: state => state.ticketNumberColumnWidth,
    publicArticles: (state) => state.publicArticles,
    publicArticlesPage: (state) => state.publicArticlesPage,
    publicArticlesPageCount: (state) => state.publicArticlesPageCount,
    helpCenterBGColor: state => state.helpCenterBGColor,
    helpCenterModernDesignEnabled: state => state.helpCenterModernDesignEnabled,
    helpCenterHyperlinksColor: state => state.helpCenterHyperlinksColor,
    isLoadingHelpCenterDesign: state => state.loadingHelpCenterDesign,
    publicFaqsEnabled: state => state.publicFaqsEnabled,
    publicArticlesEnabled: state => state.publicArticlesEnabled,
    publicArticlesCategories: state => state.publicArticlesCategories,
    publicFaqs: state => state.publicFaqs,
    helpCenterSelectedFaq: state => state.helpCenterSelectedFaq,
    helpCenterSelectedArticle: state => state.helpCenterSelectedArticle,
    includeWorkspaceEnabled: state => state.includeWorkspaceEnabled,
    emailTemplateType: state => state.emailTemplateType,
    workspaceDeletionInProgress: state => state.workspaceDeletionInProgress,
    minimizedWorkspaceDeletion: state => state.minimizedWorkspaceDeletion,
    helpCenterSubmitRequestRoute: state => state.helpCenterSubmitRequestRoute,
    customDomain: state => state.customDomain,
  },
  actions: {
    fetchTicket({ commit, state }, id) {
      const params = {
        window_guid: window.sessionStorage.getItem('windowGuid'),
        workspace_id: state.currentWorkspace,
        company_id: state.currentCompany,
      };
      return http
        .get(`/tickets/${id}.json`, { params })
        .then((res) => {
          const ticket = res.data;
          commit("setCurrentHelpTicket", ticket);
          commit("setLoadingTicket", false);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading help ticket.  ${msg}`
          );
          if (error.response.data.hideTicket) {
            window.location.href = "/help_tickets?access=denied";
          }
        });
    },
    fetchContributorOptions({ commit }) {
      http
        .get("/contributor_options.json")
        .then((res) => {
          commit("setContributorOptions", res.data);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading users/groups. ${msg}.`
          );
        });
    },
    fetchSelectedContributor({ commit }, params) {
      commit("setLoading", true);
      http
        .get("/contributor_options.json", { params })
        .then((res) => {
          commit("setSelectedContributor", res.data);
          commit("setLoading", false);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading users/groups. ${msg}.`
          );
          commit("setLoading", false);
        });
    },

    fetchDevices({ commit }) {
      http
        .get("/managed_asset_options.json")
        .then((res) => {
          const devices = res.data.assets.map((asset) => ({
              name: asset.name,
              managedAssetId: asset.id,
            }));
          commit("setDevices", devices);
        })
        .catch(() => {
          commit(
            "GlobalStore/setErrorMessage",
            "Sorry, there was an error loading available assets"
          );
        });
    },

    fetchCompanyUserOptions({ commit, state }, otherParams = null) {
      const params = {
        ...otherParams,
        company_id: state.currentCompany,
        workspace_id: state.currentWorkspace,
      };
      http
        .get("/company_user_options.json", { params })
        .then((res) => {
          commit("setCompanyUserOptions", res.data);
        })
        .catch(() => {
          commit(
            "GlobalStore/setErrorMessage",
            "Sorry, there was an error loading available users"
          );
        });
    },
    fetchCategories({ commit }, attrs={}) {
      const params = { knowledge_base : attrs.knowledgeBase };
      return http
        .get("/helpdesk_categories.json", { params })
        .then((res) => {
          commit("setCategories", res.data);
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error loading categories. (${error.response.data.message}).`);
        });
    },
    fetchCompanyLocations({ commit }) {
      commit("setLoading", true);
      http
        .get("/locations.json")
        .then((res) => {
          commit("setCompanyLocations", res.data.locations);
          commit("setLoading", false);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading location info. ${msg}`
          );
        });
    },

    fetchTicketCounts({ commit, getters }) {
      commit("setLoading", true);
      const params = getters.filterParams;

      http
        .get("/ticket_counts.json", { params })
        .then((res) => {
          commit("setSummaryCounts", res.data);
          commit("setLoading", false);
          commit("setLoadingStatus", false);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading help tickets. ${msg}`
          );
          commit("setLoading", false);
          commit("setLoadingStatus", false);
        });
    },

    fetchTickets({ commit, getters, dispatch }, payload) {
      let mspFlag = false;
      commit("setLoadingStatus", true);
      commit("setTicketLoadingStatus", true);

      if (payload?.preventListTableLoadingBehavior) {
        commit("setPreventListTableLoadingBehavior", true);
      }

      const params = getters.filterParams;
      mspFlag = payload?.mspFlag;
      params.companies_ids = payload?.companiesIds;
      params.msp_flag = mspFlag;
      params.show_closed = payload?.showClosed;
      http
        .get("/abbreviated_tickets.json", { params })
        .then((res) => {
          const {data} = res;
          commit("setRequestEmail", data.requestEmail);
          commit("setTickets", data.tickets);
          commit("setPageCount", data.pageCount);
          commit('setTotalRecord', res.data.totalTicketCount);
          commit('setCurrentContributorIds', res.data.currentContributorIds);
          if (getters.pageCount !== 0 && parseInt(getters.page, 10) + 1 > getters.pageCount) {
            commit("setPage", getters.pageCount - 1);
            dispatch("fetchTickets");
            return;
          }
          commit("setLoadingStatus", false);
          commit("setTicketLoadingStatus", false);
          commit("setPreventListTableLoadingBehavior", false);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading help tickets. ${msg}`
          );
          commit("setLoadingStatus", false);
          commit("setTicketLoadingStatus", false);
          commit("setPreventListTableLoadingBehavior", false);
        });
    },
    fetchCustomEmails({ commit }) {
      http
        .get('/help_desk_custom_emails.json')
        .then(res => {
          const { emails } = res.data;
          if (emails) {
            commit('setHelpdeskEmails', emails);
          }
        })
        .catch(() => {
          commit(
            "GlobalStore/setErrorMessage",
            'There was an issue fetching your helpdesk emails. Please refresh the page and try again.'
          );
        });
    },
    fetchWorkspaces({ commit }) {
      http
        .get(`/workspace_options.json`)
        .then((res) => {
          commit('setWorkspaceOptions', res.data);
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error loading workspaces. (${error.response.data.message}).`);
        });
    },
    loadRequestEmail({ commit }) {
      return http
        .get("/request_email.json")
        .then((res) => {
          commit("setRequestEmail", res.data.requestEmail);
        })
        .catch((error) => {
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading request email. ${error.response.data.message}`
          );
        });
    },

    createQuestion({ commit }, attrs) {
      return http
        .post(`/helpdesk_faqs.json`, {
          helpdesk_faq: attrs.faq,
          attachment_ids: attrs.attachmentIds,
        })
        .then((res) => {
          commit("setFaqs", res.data.questions);
        });
    },

    saveQuestion({ commit }, attrs) {
      if (!window.$company) {
        return null;
      }
      return http
        .put(`/helpdesk_faqs/${attrs.faq.id}.json`, {
          helpdesk_faq: attrs.faq,
          attachment_ids: attrs.attachmentIds,
        })
        .then((res) => {
          commit("setFaqs", res.data.questions);
        });
    },

    fetchFaq({ commit }, id) {
      commit("setLoadingStatus", true);
      return http
        .get(`/helpdesk_faqs/${id}.json`)
        .then((res) => {
          commit("setFaq", res.data);
          commit("setLoadingStatus", false);
        });
    },
    fetchFaqs({ commit, state }, searchTerms = null) {
      commit("setLoadingStatus", true);
      const params = { page: state.faqsPage + 1, per_page: state.faqsPerPage };
      if (searchTerms) {
        params.search_terms = searchTerms;
      }
      const url = `/helpdesk_faqs.json`;
      return http
          .get(url, { params })
          .then((res) => {
            commit("setFaqs", res.data.questions);
            commit("setLoadingStatus", false);
            commit("setFaqsPageCount", res.data.pageCount);
          });
    },
    fetchContractAssetTicketNames({ commit }) {
      return http.get("/note_links.json").then((res) => {
        commit("setContractAssetTicketNames", res.data);
      });
    },
    fetchTasks({ commit, state }, id) {
      const params = { 
        page: Math.max(0, state.page) + 1,
        per_page: state.perPage,
        company_id: state.currentCompany,
      };
      return http
        .get(`/tickets/${id}/project_tasks.json`, { params })
        .then((res) => {
          commit("setTasksCompletedCount", res.data.completedCount);
          commit("setTasksTotalCount", res.data.totalCount);
          commit("setTasks", res.data.tasks);
        });
    },
    createWindowSession() {
      const storage = window.sessionStorage;
      const sessionId = storage.getItem("windowGuid");
      if (!sessionId) {
        storage.setItem("windowGuid", uuidv4());
      }
    },
    fetchComment({ commit, getters }, comment) {
      if (!this.getters.currentHelpTicket) {
        return null;
      }
      const ticketId = getters.currentHelpTicket.id;
      // This might seem a little silly, but the ticketId helps prevent bleed over from
      // duplicate tabs that are open.
      const params = { 
        workspace_id: getters.currentWorkspace,
        company_id: getters.currentCompany,
      };
      const url = `/tickets/${ticketId}/ticket_comments/${comment.id}.json`;
      return http.get(url, { params }).then((res) => {
        if (res.data.id) {
          commit("addComment", res.data);
        }
      });
    },
    fetchComments({ commit, getters }, id) {
      const params = {
        page: getters.commentsPage + 1,
        perPage: getters.commentsPerPage,
        company_id: getters.currentCompany,
      };
      return http
        .get(`/tickets/${id}/ticket_comments.json`, { params })
        .then((res) => {
          commit("setComments", res.data.comments);
          commit("setCommentsPageCount", res.data.pageCount);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit("GlobalStore/setErrorMessage", `Sorry, there was an error loading comments. ${msg}`);
        });
    },
    fetchActivities({ commit, getters }, ticketId) {
      const id = getters.currentHelpTicket ? getters.currentHelpTicket.id : ticketId;
      if (!id) { return null; }
      const params = {
        page: getters.activitiesPage + 1,
        perPage: getters.activitiesPerPage,
        company_id: getters.currentCompany,
        workspace_id: getters.currentWorkspace,
      };
      return http
        .get(`/tickets/${id}/activities.json`, {
          params,
        })
        .then((res) => {
          commit("setActivities", res.data.helpTicketActivities);
          commit("setActivitiesPageCount", res.data.pageCount);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading activity info. ${msg}.`
          );
        });
    },
    fetchTimeSpents({ commit, state }, id) {
      const params = {
        company_id: state.currentCompany,
        workspace_id: state.currentWorkspace,
      };
      return http
              .get(`/tickets/${id}/time_spents.json`, { params })
              .then((res) => {
                commit("setTimeSpents", res.data);
              });
    },
    updateCurrentHelpTicketFormValUpdate({ commit, getters }, data) {
      const helpTicket = getters.currentHelpTicket;
      const formValueIndex = _findIndex(
        helpTicket.values,
        (v) => v.id === data.id
      );
      if (formValueIndex > -1) {
        helpTicket.values.splice(formValueIndex, 1, data);
        commit("setCurrentHelpTicket", helpTicket);
      }
    },
    updateCurrentHelpTicketFormValDelete({ commit, getters }, data) {
      const helpTicket = getters.currentHelpTicket;
      const formValueIndex = _findIndex(helpTicket.values, (v) => v.id === data);
      if (formValueIndex > -1) {
        helpTicket.values.splice(formValueIndex, 1);
        commit("setCurrentHelpTicket", helpTicket);
      }
    },
    fetchTicketActivities({ commit }, ticketId) {
      http
        .get(`/tickets/${ticketId}/activities.json`)
        .then((res) => {
          const activities = res.data.helpTicketActivities;
          const {length} = activities;
          for (let i = 0; i < length; i += 1) {
            const activity = activities[i];
            const tagName = activity.activityType.replace("_", "-");
            activity.activityComponent = `${tagName}-activity`;
            activities[i] = activity;
          }
          commit("setTicketActivities", activities);
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error loading activity info. ${msg}.`
          );
        });
    },
    fetchTicketEmails({ commit }, params) {
      http
        .get('/ticket_emails.json', { params })
        .then((res) => {
          const ticketEmails = res.data.emails;
          commit('setTicketEmails', ticketEmails);
        });
    },

    fetchTicketListColumns({ commit }) {
      const params = { workspace_id: getWorkspaceFromStorage().id };

      return http
        .get('/ticket_list_columns.json', { params })
        .then((res) => {
          commit('setDefaultSelectedColumns', res.data.defaultSelectedCols);
          commit('setDefaultUnSelectedColumns', res.data.defaultUnselectedCols);
          commit('setHelpTicketPreferences', res.data.ticketListColumn.columns);
        })
        .catch((error) => {
          commit('GlobalStore/setErrorMessage', `Sorry, there was an error fetching ticket list columns. ${error.message}`);
        });
    },
    updateTicketListColumns({ commit }, value) {
      const params = { workspace_id: getWorkspaceFromStorage().id };
      params.selected_columns = value;

      commit('setHelpTicketPreferences', value);

      return http
        .put('/ticket_list_columns.json', params)
        .catch((error) => {
          commit('GlobalStore/setErrorMessage', `Sorry, there was an error saving data. ${error.response.data.message}`);
        });
    },
    updateCurrentHelpTicketFormValCreate({ commit, getters }, data) {
      const helpTicket = getters.currentHelpTicket;
      helpTicket.values.push(data);
      commit("setCurrentHelpTicket", helpTicket);
    },
    destroyTicketSession({ commit }) {
      const storage = window.sessionStorage;
      const id = storage.getItem("windowGuid");
      return http
        .delete(`/ticket_sessions/${id}.json`)
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit('GlobalStore/setErrorMessage', `Sorry, there was an error clearing session. ${msg}`);
        });
    },
    fetchFormValue: ({ commit, getters }, id) => {
      if (!getters.currentHelpTicket) {
        return null;
      }
      return http
        .get(`/tickets/${getters.currentHelpTicket.id}/custom_form_values.json?custom_form_field_id=${id}`, { params: { custom_form_field_id: id, company_id: getters.currentCompany } })
        .then(res => {
          const obj = { ...getters.currentHelpTicket };
          obj.values = obj.values.filter(v => v.customFormFieldId !== id);
          res.data.forEach(r => obj.values.push(r));
          commit("setCurrentHelpTicket", { ...obj });
        })
        .catch((error) => {
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error clearing session.  ${msg}`
          );
        });
    },
    fetchPublicArticles({ commit, state }, attrs = {}) {
      commit("setLoading", true);
      const params = { page: state.publicArticlesPage + 1, per_page: state.publicArticlesPerPage };
      if (attrs.search) {
        params.search_terms = attrs.search;
      }
      if (attrs.categories) {
        params.categories = attrs.categories;
      }
      if (attrs.companyWideSearch) {
        params.company_wide_search = attrs.companyWideSearch;
      }
      return http
          .get('/knowledge_base.json', { params })
          .then((res) => {
            commit("setPublicArticles", res.data.articles);
            commit("setPublicArticlesPageCount", res.data.pageCount);
            commit("setLoading", false);
          })
          .catch((error) => {
            commit("setLoading", false);
            const msg = _get(error, "response.data.message", "");
            commit(
              "GlobalStore/setErrorMessage",
              `Sorry, there was an error fetching public articles.  ${msg}`
            );
          });
    },
    fetchPublicFaqs({ commit }, attrs = {} ) {
      commit("setLoading", true);
      const params = { help_center: attrs.helpCenter };
      if (attrs.searchTerms) {
        params.search_terms = attrs.searchTerms;
      }
      if (attrs.companyWideSearch) {
        params.company_wide_search = attrs.companyWideSearch;
      }
      const url = `/faqs.json`;
      return http
          .get(url, { params })
          .then((res) => {
            commit("setLoading", false);
            commit("setPublicFaqs", res.data.questions);
          })
          .catch((error) => {
            commit("setLoading", false);
            const msg = _get(error, "response.data.message", "");
            commit(
              "GlobalStore/setErrorMessage",
              `Sorry, there was an error fetching public FAQs.  ${msg}`
            );
          });
    },
    fetchHelpCenterDesign({ commit }) {
      commit("setLoadingHelpCenterDesign", true);
      return http
        .get(`/help_center.json`)
        .then((res) => {
          commit('setHelpCenterModernDesignEnabled', res.data.modernDesignEnabled);
          commit('setPublicFaqsEnabled', res.data.publicFaqsEnabled);
          commit('setPublicArticlesEnabled', res.data.publicArticlesEnabled);
          commit("setLoadingHelpCenterDesign", false);
        })
        .catch((error) => {
          commit("setLoadingHelpCenterDesign", false);
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error fetching help center design.  ${msg}`
          );
        });
    },
    fetchHelpCenterColors({ commit }) {
      commit("setLoading", true);
      return http
        .get(`/help_center.json`)
        .then((res) => {
          commit("setLoading", false);
          commit('setHelpCenterBGColor', res.data.bgColor);
          commit('setHelpCenterHyperlinksColor', res.data.hyperlinksColor);
          commit('setPublicFaqsEnabled', res.data.publicFaqsEnabled);
          commit('setPublicArticlesEnabled', res.data.publicArticlesEnabled);
        })
        .catch((error) => {
          commit("setLoading", false);
          const msg = _get(error, "response.data.message", "");
          commit(
            "GlobalStore/setErrorMessage",
            `Sorry, there was an error fetching help center configs.  ${msg}`
          );
        });
    },
    fetchCustomDomain({ commit }) {
      commit("setLoading", true);
      http
        .get('/custom_domains.json')
        .then(res => {
          if (res.data.domain) {
            commit('setCustomDomain', res.data.domain);
          }
          commit("setLoading", false);
        })
        .catch(() => {
          commit('GlobalStore/setErrorMessage', 'There was an error fetching your custom domain. Please refresh the page and try again.');
          commit("setLoading", false);
        });
    },
    updateCustomDomain({ commit, getters }, updateLogo = false) {
      commit('setLoading', true);
      const payload = {
        custom_domain: getters.customDomain,
        update_logo: updateLogo,
      };
      http
        .put(`/custom_domains/${getters.customDomain.id}`, payload)
        .then((res) => {
          commit('setCustomDomain', res.data.domain);
          commit('setLoading', false);
        })
        .catch((error) => {
          commit('GlobalStore/setErrorMessage', `Sorry, there was an error updating custom domain. ${error.response.data.message}`);
          commit("setLoading", false);
        });
    },
  },
  plugins: [
    createPersistedState({
      localStorage: {
        getState: key => window.Cookies.getJSON(key),
        setState: (key, state) => window.Cookies.set(key, state, { expires: 3 }),
        removeItem: key => window.Cookies.remove(key),
      },
      paths: [
        'inDepthTicketReport.insightsSelectedChartTypes',
      ],
    }),
  ],
});
