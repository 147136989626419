<template>
  <div>
    <input
      ref="input"
      v-model="valueStr"
      class="form-control"
      type="number"
      :required="required"
      @input="emitValue($event.target.value)"
    >
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';

export default {
  mixins: [fieldViewerInput, permissionsHelper],
  props: {
    field: {
      type: Object,
    }, 
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      valueStr: _get(this, "value"),
    };
  },
  methods: {
    onWorkspaceChange() {
      this.setValueStr(this.field);
    },
    emitValue: _debounce(function emitValue() {
      this.errors = null;
      this.$emit("input", this.valueStr);
    }, 1000),
    showErrors() {
      let msg = null;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.valueStr) {
        msg = `Please enter a ${this.field.label}`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>

