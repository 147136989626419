import { render, staticRenderFns } from "./priority.vue?vue&type=template&id=f8e2bb84&scoped=true&"
import script from "./priority.vue?vue&type=script&lang=js&"
export * from "./priority.vue?vue&type=script&lang=js&"
import style0 from "./priority.vue?vue&type=style&index=0&id=f8e2bb84&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8e2bb84",
  null
  
)

export default component.exports