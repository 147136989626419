<template>
  <div>
    <input
      ref="input"
      class="form-control"
      placeholder="Enter text"
      :value="valueStr"
      data-tc-text
      maxlength="200"
      data-tc-task-value
      @input="emitValue($event.target.value)"
    >
    <span
      v-if="errors"
      class="form-text small text-danger"
      :data-tc-field-error-messages="errors"
    >
      {{ errors }}
    </span>
  </div>
</template>

<script>
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import customFormHelper from 'mixins/custom_form_helper';

export default {
  mixins: [ fieldViewerInput, permissionsHelper, customFormHelper ],
  props: {
    field: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: null,
      errorMsg: '',
      userFields: ['first_name', 'last_name', 'email'],
      companyModule: '',
      valueStr: _get(this, "value", ""),
    };
  },
  computed: {
    targetUser() {
      return this.field.targetUser;
    },
  },
  methods: {
    onWorkspaceChange() {
      this.companyModule = this.getCompanyModule;

      this.setTextOrEmailFieldValue();
    },
    emitValue: _debounce(function emitValue(value) {
      this.errors = null;
      const text = value;
      this.$emit("input", text.trim());
    }, 1000),
    setErrors(msg) {
      this.errorMsg = msg;
    },
    showErrors() {
      let msg = null;
      const cityRegex = /^[a-zA-Z\s]*$/;
      const nameRegex =  /^[a-zA-Z0-9\u00C0-\u017F]([\w\s\-'\u00C0-\u017F]*[a-zA-Z0-9\u00C0-\u017F.'])?$/;
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.value || this.value.length === 0) {
        msg = `Please enter a ${this.field.label}.`;
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else if (this.field.name === "city" && !cityRegex.test(this.value)) {
        msg = "The city field may only contain alphabetic characters as well as spaces";
        this.$refs.input.setCustomValidity(msg);
        this.errors = msg;
      } else if (this.field.name === "first_name" || this.field.name === "last_name") {
        if (this.value.length < 2 || this.value.length > 26) {
          msg = `The ${this.field.label} field must be between 2 and 26 characters`;
          this.$refs.input.setCustomValidity(msg);
        } else if (!nameRegex.test(this.value)) {
          msg = `${this.field.label} field format is invalid`;
          this.$refs.input.setCustomValidity(msg);
        } else {
          this.$refs.input.setCustomValidity('');
        }
        this.errors = msg;
      } else if (this.value && this.errorMsg.length > 0) {
        this.$refs.input.setCustomValidity(this.errorMsg);
        this.errors = this.errorMsg;
      } else {
        this.$refs.input.setCustomValidity('');
        this.errors = null;
      }
      return !this.errors;
    },
  },
};
</script>
