<template>
  <span v-if="field">
    <smart-list
      v-if="showSmartList"
      ref="smartList"
      :field="field"
      :value="value"
      :options-url="computedOptionsUrl"
      plural-name="users"
      single-name="a user"
      :disabled="disabled"
      :required="required"
      @input="emitInput"
      @open="openLink"
      @delete="deleteValue"
    >
      <template slot="label">
        <slot name="label" />
      </template>

      <template #image="{ option }">
        <user-avatar
          :username="option.name"
          :src="option.avatarThumbUrl"
          :size="20"
          class="logo-outline"
        />
      </template>
    </smart-list>
    <div v-else>
      <input
        ref="input"
        v-model="textValue"
        class="form-control"
        maxlength="200"
        placeholder="Enter email address"
        data-tc-text
        @input="emitText"
      >
    </div>
    <span
      v-if="errors"
      class="form-text small text-danger"
    >
      {{ errors }}
    </span>
  </span>
</template>

<script>
import _get from "lodash/get";
import _compact from 'lodash/compact';
import { mapActions, mapGetters } from 'vuex';
import { Avatar as UserAvatar } from 'vue-avatar';
import fieldViewerInput from 'mixins/field_viewer_input';
import permissionsHelper from 'mixins/permissions_helper';
import strings from 'mixins/string';
import loggedIn from 'mixins/logged_in';
import SmartList from './smart_list.vue';

export default {
  components: {
    SmartList,
    UserAvatar,
  },
  mixins: [ fieldViewerInput, permissionsHelper, strings, loggedIn ],
  props: { 
    includeCurrent: {
      default: false,
    },
  },
  data() {
    return {
      textValue: null,
      errors: null,
    };
  },
  computed: {
    ...mapGetters([
      'helpdeskEmails',
      'companyUserOptions',
    ]),
    computedOptionsUrl() {
      const url = _get(this, "field.optionsUrl");
      if (url) {
        return url;
      }
      return this.field.audience === "guests" ? "/contributor_options_with_guests.json" : "/contributor_options.json";
    },
    showSmartList() {
      return this.isLoggedIn || this.field.name !== 'created_by';
    },
    label() {
      if (this.field.label) {
        return this.field.label;
      } else if (this.field.name) {
        return this.field.name.replace(/_/g, " ");
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["fetchCompanyUserOptions"]),
    onWorkspaceChange() {
      if (!this.showSmartList) {
        this.fetchCompanyUserOptions({ archived_users_only : true });
      }
      this.emitInput(this.value);
    },
    emitInput(ids) {
      this.errors = null;
      this.$emit('input', ids);
    },
    showErrors() {
      let msg = "";
      if (!this.required && this.moduleName !== "automatedTasks") {
        return false;
      }
      if (!this.showSmartList) {
        if (!this.textValue || this.textValue.length === 0) {
          msg = `Please enter ${this.label}`;
        } else if (!this.validateEmail(this.textValue)) {
          msg = `Must be an email address`;
        } else if (this.isCustomEmail(this.textValue)) {
          msg = 'Sorry, this email is already used in custom forms. Please enter a different email address.';
        } else if (this.isArchivedUser(this.textValue)) {
          msg = 'Sorry, an archived user with this email already exists. Please enter a different email address.';
        }
      } else {
        let myValues = _get(this, "value", []);
        if (myValues instanceof Array) {
          myValues = _compact(myValues);
          if (!myValues.length) {
            if (['followers', 'impacted users'].includes(this.label)) {
              msg = `Select ${this.label}`;
            } else if (this.label === 'raise this_request_on_behalf_of') {
              msg = `select a ${this.snakeToHumanize(this.label).toLowerCase()}`;
            } else {
              msg = `Select a ${this.label}`;
            }
          }
        } else if (!myValues) {
            msg = `Select a ${this.label}`;
        } else if (typeof(myValues) === "string" && !this.validateEmail(myValues)) {
          msg = `Must be an email address`;
        } else if (this.isCustomEmail(myValues)) {
          msg = 'Sorry, this email is already used in custom forms. Please select a different email address.';
        }
      }
      if (this.$refs.input) {
        this.$refs.input.setCustomValidity(msg);
      }
      this.errors = msg;
      return !this.errors;
    },
    openLink(resource) {
      window.open(`/contributors/${resource.id}`, "_blank");
    },
    deleteValue(value) {
      this.$emit('delete', value);
    },
    emitText() {
      this.$emit("input", { id: this.textValue, name: this.textValue });
    },
    isCustomEmail(currentUserEmail) {
      return this.helpdeskEmails && this.helpdeskEmails.includes(currentUserEmail);
    },
    isArchivedUser(currentUserEmail) {
      return this.companyUserOptions?.find(u => u.email === currentUserEmail);
    },
  },
};
</script>
