<template>
  <div class="col-4 px-">
    <router-link
      v-tooltip="{
        content: toolTipContent,
        placement: 'bottom',
      }"
      class="box box--natural-height p-4 mt-n6"
      :class="{ 'box--with-hover': !isDisabled}"
      :to="isDisabled ? '' : path"
    >
      <div class="box__inner">
        <img
          :src="require(`images/${image}`)"
          height="92"
          class="mt-n1"
        >
        <h4 class="font-weight-bold mt-2 mb-1">{{ title }}</h4>
        <p class="mb-0 text-secondary not-as-small px-3">
          {{ description }}
        </p>
      </div>
      <span
        v-if="isDisabled"
        class="box--disabled-overlay t-0"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    selectedWorkspaceName: {
      type: String,
      default: '',
    },
  },
  computed: {
    toolTipContent() {
      return this.isDisabled ? (`No forms are available for the workspace '${this.selectedWorkspaceName}'`) : null;
    },
  },
};
</script>

